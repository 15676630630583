@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
    overflow: hidden;
}

.grid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    grid-template-rows: auto;
    margin-bottom: 60px;
    @media (min-width: 1280px) {
        gap: 21px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
    }
}

.col{
    grid-template-columns: 1fr;
    grid-template-rows: 150px 150px;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    height: clamp(200px, 15.41vw, 296px);
    &:nth-child(2){
        grid-template-columns: 1fr;
        height: clamp(200px, 15.41vw, 296px);
    }
    @media (min-width: 768px) {
        gap: 15px;
        height: clamp(296px, 21.8vw, 420px);
        &:nth-child(2){
            grid-template-columns: 1fr;
            height: clamp(296px, 21.8vw, 420px);
        }
    }
    @media (min-width: 1280px) {
        height: auto;
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 420px 420px;
        &:nth-child(1){
            display: grid;
            grid-template-rows: minmax(260px, 420px) 260px;
            gap: 21px;
        }
        &:nth-child(2){
            display: grid;
            grid-template-rows: 1fr;
            height: auto;
        }
        &:nth-child(3){
            display: grid;
            grid-template-rows: repeat(2,340px);
            gap: 21px;
        }
    }
}