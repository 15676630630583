@import "../../../styles/_variables.scss";

.container{
    padding: 128px 3px 70px;
}
.title{
    color: $title;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(48px, 3.33vw, 64px);
    line-height: 100%;
    font-weight: 400;
    white-space: pre-line; 
}
.desc{
    margin-top: 32px;
    color: $primary_text__light;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(14px,1.04vw,20px);
    text-align: center;
    font-weight: 400;
}