.section {
    padding-top: 68px;
    padding-bottom: 46px;
    position: relative;
    overflow: hidden;
}

.row{
    display: flex;
    justify-content: center;
    gap: 30px;
}