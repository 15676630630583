@import "../../styles/_variables.scss";

.container{
    max-width: 960px;
    position: relative;
    margin: 0 auto;
}
.wrapper{
    margin: 0 15px;
    min-height: 279px;
    height: 100%;
    border-radius: 24px;
    // padding: 20px 15px;
    @media (min-width: 996px) {
        // padding: 24px;
        background:  rgba(194, 208, 251, 0.20);
        backdrop-filter: blur(50px);
    }
}
.slide{
    width: 100%;
    height: 100%;
    background:  rgba(194, 208, 251, 0.20);
    backdrop-filter: blur(50px);
    padding: 24px;
    border-radius: 24px;
    flex-direction: column;
    display: flex;
    @media (min-width: 996px) {
        backdrop-filter: unset;
        background: unset;
    }
}
.swiper__slide{
    align-self: stretch;
   @media (min-width: 996px) { 
    align-self: unset;
   }
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
    transform: translateY(-20px);
    span {
        width: 10px;
        height: 10px;
        background-color: #fff;
        cursor: pointer;
    }
}
.content{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    blockquote{
        color: $title;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        margin: 16px 0px;
        max-width: 1020px;
    }
}

.user{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.user__preview{
    border-radius: 100%;
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
    margin-top: 8px;
}
.user__name{
    color: $title;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
}

.navigation__btn {
    display: none;

    @media (min-width: 996px) {
        display: block;
        position: absolute;
        z-index: 6;
        top: 50%;
        transform: translateY(200%);
        background:  rgba(194, 208, 251, 0.05);
        backdrop-filter: blur(7.5px);
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
        width: 96px;
        height: 72px;
        padding: 16px;
        transform: translateY(-50%);
    }
    @media (hover: hover) {
        &:hover{
            opacity: 0.8;
        }
    }
}

.navigation__btn__next {
    right: 15px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 15px;
    border-radius: 0px 80px 80px 0px;
    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}