@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.pb20{
    padding-top: 20px;
}
.pb28{
    padding-bottom: 32px;
    @media (min-width:1280) {
        padding-bottom: 28px;
    }
}
.row{
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media (min-width: 1280px) {
        flex-direction: row;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 124px;
    }
}
.col{
    width: 100%;
    &:nth-child(2){
        margin-bottom: 40px; 
    }
    @media (min-width: 1280px) {
        padding-left: 10px;
        padding-right: 10px;
        &:nth-child(1){
            max-width: 736px;
            width: 100%;
        }
        &:nth-child(2){
            max-width: 440px;
            width: 100%;
            margin-bottom: 40px; 
        }
    }
}

.carousel{
    height: 432px;
    margin-bottom: 24px;
    @media (min-width: 680px) {
        height: 648px;
        margin-bottom: 153px;
        &.null{
            margin-bottom: 0px; 
        }
    }
    @media (min-width: 1280px) {
        margin-bottom: 0px;
    }
}

.box{
    padding-top: 15px;
    padding-bottom: 10px;
}

.title{
    color:  $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(28px,1.8vw,36px);
    font-weight: 500;
    line-height: 100%;
    @media (min-width:1280) {
        font-weight: 400;
    }
}

.price{
    color:  #fff;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 36px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
    padding-bottom: 13px;
    span{
        color:  rgba(235, 233, 240, 0.80);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 17px;
        font-weight: 500;
        margin-right: 4px;
    }
}

.cart{
    min-width: 206px;
    height: 60px;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 1;
    z-index: 2;
    width: 100%;
    border-radius: 16px;
    span{
        border-radius: 16px!important;
    }
}


.cart_go{
    z-index: 1;
}

.actions{
    min-width: 206px;
    position: relative;
    height: 60px;
    margin: 0 auto;
    max-width: 500px;
}

.description__wrap{
    margin-top: 40px;
    margin-bottom: clamp(40px,4.1vw,80px);
}
.second__title{
    color:  $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
    padding-bottom: 16px;
}
.description{
    color:  rgba(235, 233, 240, 0.80);
    overflow: hidden;
    font-size: 14px;
    h3,p,h2,h1,ul{
        padding-bottom: 10px;
    }
}

.title__skeleton{
    height: 64px;
    width: 230px;
    margin-bottom: 4px;
    border-radius: 10px;
    margin-top: 20px;
    @media (min-width: 1280px) {
        margin-top: 0px;
    }
}
.price__skeleton{
    height: 46px;
    width: 100px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.cart__skeleton{
    width: 206px;
    height: 40px;
    border-radius: 40px;
}

.loading{
    margin-bottom: 30px;
}
