.section{
    padding-top: clamp(15px,3.1vw,60px);
    position: relative;
    z-index: 1;

    @media only screen and (min-width: 1280px) {
        display: none;
    }
}

.card__post{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        top: 0;
        left: 0;
    }
    &__info{
        position: absolute;
        width: 100%;
        z-index: 2;
        color: #fff;
        font-weight: 500;
        font-size: clamp(16px,1.25vw,24px);
        line-height: 100%;
        letter-spacing: 0.03em;
        bottom: 0px;
        left: 0px;
        padding: 16px;
        @media (min-width: 768px) {
            padding: 60px 20px 30px;
        }
    }
}

.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
}
.row__item{
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    height: 260px;
    @media (min-width: 768px) {
        width: calc(50% - 10px);
        height: 320px;
    }
    @media (min-width: 996px) {
        width: calc(33.3333% - 10px);
        height: 420px;
    }
}

.grid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    grid-template-rows: auto;
    @media (min-width: 768px) {
        gap: 20px;
        grid-template-rows: 420px;
    }
    @media (min-width: 1280px) {
        gap: 32px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
    }
}

.col{
    grid-template-columns: 1fr;
    grid-template-rows: 144px 144px;
    display: grid;
    gap: 10px;
    &:nth-child(2){
        grid-template-columns: 1fr;
        grid-template-rows: 144px;
        .six{
            grid-template-columns: 1fr;
            grid-template-rows: 144px;
        }
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 420px;
        gap: 20px;
        &:nth-child(2){
            grid-template-columns: 1fr;
            grid-template-rows: 420px;
            &.six{
                grid-template-rows: 420px;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }
        }
    }
    @media (min-width: 1280px) {
        gap: 32px;
        grid-template-columns: 1fr;
        grid-template-rows: 420px 420px;
        &:nth-child(1){
            display: grid;
            grid-template-rows: minmax(260px, 420px) 260px;
            gap: 32px
        }
        &:nth-child(2){
            display: grid;
            grid-template-rows: 1fr;
            &.six{
                grid-template-rows: 260px minmax(260px, 420px);
                grid-template-columns: 1fr;
                gap: 32px
            }
        }
        &:nth-child(3){
            display: grid;
            grid-template-rows: 260px minmax(260px, 420px);
            gap: 32px;
            &.six{
                grid-template-rows: minmax(260px, 420px) 260px ;
                grid-template-columns: 1fr;
            }
        }
    }
}

.order{
    order: 1;
}