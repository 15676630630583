@import "../../../styles/variables";

.content {
    width: 90%;
    height: 90%;
    padding: 0px;
    background-color: rgba(34, 2, 60, 0.80);
    border-radius: 24px;
    overflow: hidden;
    max-height: 590px;
    @media (min-width: 996px) {
        max-width: 540px;
        width: 100%;
        max-height: 700px;
    }
    @media (min-width: 1280px) {
        max-height:760px;
        height: auto;
    }
    @media (min-width: 1440px) {
        max-height: 860px;
    }
    @media (max-height: 860px) {
        min-height: 320px;
    }
}

.container {
    height: 100%;
    width: 100%;
    position: relative;
}

.cursor{
    cursor: pointer;
}

.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    // padding: 0px 10px;

    @media (min-width: 996px) {
        // padding: 0px 20px 20px 20px;
        max-height: 700px;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
          background: rgba(194, 208, 251, 0.20);
    }

    &::-webkit-scrollbar-thumb {
        background-color:  rgba(194, 208, 251, 0.10);
    }
}

.preview {
    width: 100%;
    height: clamp(224px,28.02vw,538px);
    justify-content: center;
    object-fit: cover;
    object-position: top;
}

.name {
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(36px, 2.3vw, 46px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
}


.header__top{
    position: relative;
    overflow: hidden;
    &__content{
        padding: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        width: 100%;
        // background: rgba(0, 0, 0, 0.01);
        // /backdrop-filter: blur(5px);
        // -webkit-box-shadow: inset 0px -8px 23px 3px rgba(198,150,87,0.71);
        // -moz-box-shadow: inset 0px -8px 23px 3px rgba(198,150,87,0.71);
        //box-shadow:  inset 0 -33px 67px -8px rgba(198, 150, 87, 0.7);
        background: linear-gradient(180deg, rgba(198,150,87,0.01) 0%, rgba(198,150,87,0.3) 29.54%, rgba(198,150,87,0.4) 100%);
    }
}

.about{
    margin-top: 16px;
    color: $primary_text__light;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
}
.chips {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.chips__item {
    color: #C2D0FB;
    border-radius: 32px;
    background: rgba(130, 75, 209, 0.10);
    border: 1.5px solid rgba(130, 75, 209, 0.05);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(12px, 0.72vw, 14px);
    font-weight: 400;
    padding: 8px 16px;
    cursor: pointer;
    &:nth-child(1n){
        color: #C2D0FB;
        background: rgba(130, 75, 209, 0.10);
        border: 1.5px solid rgba(130, 75, 209, 0.05);
    }
    &:nth-child(2n){
        background: rgba(255, 226, 2, 0.10);
        border: 1.5px solid rgba(255, 226, 2, 0.05);
        color: rgba(255, 226, 2, 0.80); 
    }
    &:nth-child(3n){
        color: #F460E6;
        background: rgba(244, 96, 230, 0.05);
        border: 1.5px solid rgba(244, 96, 230, 0.05);
    }
    &:nth-child(4n){
        color: #4080FF;
        background: rgba(64, 128, 255, 0.10);
        border: 1.5px solid rgba(64, 128, 255, 0.05);
    }
    &.yellow{
        background: rgba(255, 226, 2, 0.10);
        border: 1.5px solid rgba(255, 226, 2, 0.05);
        color: rgba(255, 226, 2, 0.80); 
    }
    &.pink{
        color: #F460E6;
        background: rgba(244, 96, 230, 0.05);
        border: 1.5px solid rgba(244, 96, 230, 0.05);
    }
    &.blue{
        color: #4080FF;
        background: rgba(64, 128, 255, 0.10);
        border: 1.5px solid rgba(64, 128, 255, 0.05);
    }
}

.section{
    padding: 24px;
    border-bottom: 1px solid rgba(235, 233, 240, 0.1);
    &__title{
        color: $title;
        margin-bottom: 16px;
    }
    &__desc{
        color: $primary_text__light;
    }
}

.section__footer{
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socials{
    display: flex;
    gap: 10px
}

.btn{
    width: 132px;
    height: 44px;
}