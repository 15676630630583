@import "../../../../styles/variables";

.date {
  color: $title;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  font-variant-numeric: lining-nums proportional-nums;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.duration  {
  color: rgba(235, 233, 240, 0.40);
  font-size: 14px;
  font-weight: 500;
  font-variant-numeric: lining-nums proportional-nums;
}