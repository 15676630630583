.aside{
    width: 100%;
    padding: 28px 16px;
    @media (min-width: 980px) {
        position: sticky;
        top: 0;
        left: unset;
        width: 280px;
        height: auto;
    }
}


.aside__margin__top{
    margin-top: 62px;
}
.main{
    width: 100%;
}

.main__aside{
    @media (min-width: 1280px) {
        width: calc(100% - 300px);
    }
}

.paper{
    
    border-radius: 24px;
    background:  rgba(194, 208, 251, 0.20);
    backdrop-filter: blur(50px);
}

.aside__mobile__tabs{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 10px 20px;
    z-index: 1000;
    height: 59px;
    border-radius: 24px 24px 0px 0px;
    @media (min-width: 980px) {
        padding: 30px 20px;
        position: sticky;
        top: 0;
        left: unset;
        width: 280px;
        height: auto;
        border-radius: 24px;
    }
    
}

.row__container{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 80px;
    flex-direction: column;
    gap: 40px;
    @media (min-width: 980px) {
        gap: 20px;
        flex-direction: row;
    }
}
