@import "../../styles/variables";

.header {
  display: flex;
  align-items: center;
  color: $title;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: clamp(14px, 0.93vw, 18px);
  font-weight: 700;
  padding-bottom: 10px;

  svg {
    margin-right: 15px;
  }
}

.masters {
  margin-top: 20px;
  color: #494187;
}

.masters__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  row-gap: 20px;

  &__item {
    padding-left: 8px;
    padding-right: 8px;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    @media (min-width: 1280px) {
      width: 25%;
    }
  }
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  object-fit: cover;
}

.name {
  color: rgba(235, 233, 240, 0.80);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
}