.title {
  font-size: clamp(32px, 2vw, 46px);
  font-weight: 500;
  line-height: clamp(27px, 2vw, 39px);
  letter-spacing: 0.03em;
  text-align: left;
}

.sectionContainer {
  max-width: 904px;
  width: 100%;
  margin: 40px auto;

  @media (min-width: 768px) {
    margin: 40px auto 124px;
  }
}
