.counter{
    display: flex;
    align-items: center;
}
.counter__btn{
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
        width: 20px;
        height: 20px;
    }
    svg,rect{
        fill-opacity: 0.1;
        transition: fill-opacity 200ms ease;
    }
    &:hover{
        svg,rect{
            fill-opacity: 0.3;
            transition: fill-opacity 200ms ease;
        }
       
    }
}
.counter__value{
    color:  #EBE9F0;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(12px,0.91vw,17px);
    font-weight: 500;
    width: auto;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}