.card {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    cursor: pointer;
    @media (hover: hover) {
        &:hover{
            .card__img{
                border-radius: 24px;
                transition: transform 300ms ease;
                transform: scale(1.2);
            }

        }
    }
    // @media (min-width: 1280px) {
    //     &:hover{
    //         .card__info__position{
    //             opacity: 0;
    //             transition: opacity 200ms ease;
    //         }

    //         .card__info__name{
    //             transform: translateY(calc(-100% - 15px));
    //             transition: transform 300ms ease;
    //         }

    //         .link{
    //             opacity: 1;
    //             transition: all 350ms ease;
    //         }
    //     }
    // }
}

.card__img {
    width: 100%;
    border-radius: 24px;
    max-height: 664px;
    object-fit: cover;
    object-position: center;
    min-height: 432px;
    aspect-ratio: 16/19;
    transition: transform 300ms ease;
    transform: scale(1);
    &__skeleton{
        max-height: 664px;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        aspect-ratio: 16/19;
    }
}

.card__info {
    margin-top: 10px;
    padding: 24px 16px;
    position: absolute;
    overflow: hidden;
    border-radius: 0px 0px 24px 24px;
    transition: transform 300ms ease;
    z-index: 3;
    bottom: 0;
    width: 100%;
    left: 0;
    &::before{
        content: "";
        background: linear-gradient(to top, rgba(255, 255, 255, 0.30), rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0));
        backdrop-filter: blur(1px);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
    }
    &__skeleton{
        background-color: transparent;
    }
    @media (min-width: 996px) {
        padding: 48px 48px 48px 48px;
    }
}

.card__info__position {
    font-size: clamp(14px, 0.83vw, 16px);
    font-weight: 500;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 2;
    font-size: 17px;
    color: rgba(235, 233, 240, 0.80);
}

.card__info__name {
    font-size: clamp(32px, 2.39vw, 46px);
    font-weight: 500;
    position: relative;
    z-index: 2;
    transform: translateY(0%);
    transition: transform 300ms ease;
    @media (min-width: 1024px) {
        white-space: nowrap;
    }
}