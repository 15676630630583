@import "../../../styles/_variables.scss";

.section {
    scroll-margin-top: 70px;
}

.bg {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
}

.row {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 3;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.colLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 1000ms ease;
    @media screen and (min-width: 768px) {
        width: 31%;
    }
}

.colRight {
    width: 100%;
    padding-top: 20px;
    position: relative;
    overflow: hidden;
    padding-bottom: 95px;
    transform: translateX(100%);
    transition: transform 1000ms ease;
    @media screen and (min-width: 768px) {
        width: 69%;
        padding-left: 22px;
    }
}

.container {
    overflow: hidden;
    margin-left: 15px;

    @media screen and (min-width: 768px) {
        margin-left: 30px;
    }

    @media screen and (min-width:1280px) {
        margin-left: 50px;
    }
}

.swiper__misson {
    &__slide {
        height: clamp(480px, 36.4vw, 700px);
        overflow: hidden;
        border-radius: 32px;
        background-color: $surface_light;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &__img {
            width: 100%;
            height: clamp(300px, 26vw, 500px);
            object-fit: cover;
            object-position: center;
            position: relative;
            z-index: 2;
        }

        &__footer {
            padding-top: clamp(25px, 1.8vw, 35px);
            padding-bottom: clamp(25px, 1.8vw, 35px);
            padding-left: clamp(15px, 1.5vw, 30px);
            padding-right: clamp(15px, 1.5vw, 30px);
            position: relative;
            z-index: 2;
        }

        &__title {
            font-size: clamp(32px, 1.4vw, 46px);
            font-style: normal;
            font-weight: 400;
            line-height: 85%;
            letter-spacing: 1.38px;
            margin-bottom: 20px;
            text-align: right;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-variant-numeric: lining-nums proportional-nums;
        }

        &__subtitle {
            font-size: clamp(16px, 0.9vw, 20px);
            font-weight: 400;
            text-align: right;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    transform: rotate(160deg);
}

.navigation__btn {
    display: none;

    @media (min-width: 1280px) {
        display: block;
        width: 140px;
        position: absolute;
        z-index: 6;
        top: 50%;
        transform: translateY(-150%);
        background: $surface_light;
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
        backdrop-filter: blur(7.5px);
        cursor: pointer;
        height: 80px;
        padding: 24px;
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
        &:hover{
            opacity: 0.8;
        }
    }

}

.navigation__btn__next {
    right: 0px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 20px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}

.viewText,.viewSlider{
    transform: translateX(0px);
}