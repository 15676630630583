@import "src/styles/_variables.scss";

.info__count {
  pointer-events: none;
  color: #C69657;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 10px;
  }
}

.info__count__values {
  color: $text_data_60;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;


  span {
    color: $text_data_40;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}