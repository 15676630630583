.card__post{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    @media (min-width: 768px) {
        border-radius: 24px;
    }
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        top: 0;
        left: 0;
        transform: scale(1);
        transition: transform 300ms ease;
        @media (min-width: 1280px) {
            object-position: center; 
        }
    }
    &__info{
        background: linear-gradient(0deg, rgba(26, 25, 25, 0.5) 0%, rgba(85, 85, 84, 0) 100%);
        position: absolute;
        width: 100%;
        z-index: 2;
        color: rgb(255, 255, 255);
        bottom: 0px;
        left: 0px;
        padding: 16px;
        @media (min-width: 768px) {
            padding: 32px;
        }
        p{
            font-size: clamp(16px,1.294vw,36px);
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.03em;
            word-break: break-word;
        }
    }

    @media (hover: hover) {
        &:hover{  
            .card__post__image{
                transition: transform 300ms ease;
                transform: scale(1.05);
            }      
        }
    }
}