@import "../../../styles/_variables.scss";

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 10px 0;
    left: 0;
    top: 0;
    justify-content: center;
    display: flex;
    z-index: -1;
    visibility: hidden;
    transition: all 300ms ease;
    overflow: hidden;

    &.portal {
        position: absolute;
    }

    &_active {
        z-index: 4002;
        visibility: visible;

        & .modal-overlay {
            opacity: 1;
        }

        .modal-content.modal-content__animate-left {
            transform: translateX(0%);
            transition: transform 300ms ease;
        }

        .modal-content.modal-content__animate-right {
            transform: translateX(0%);
            transition: transform 300ms ease;
        }

        .modal-content.modal-content__animate-fade {
            opacity: 1;
            transition: opacity 300ms ease;
        }
    }

    &_center {
        align-items: center;
    }

    &_end {
        align-items: flex-end;
    }

    &_start {
        align-items: flex-start;
    }

    &_justify-center {
        justify-content: center;
    }

    &_justify-end {
        justify-content: flex-end;
    }

    &_justify-start {
        justify-content: flex-start;
    }

    &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 20004;
        background: rgba(32, 33, 36, 0.9);
        opacity: 0;
        transition: opacity 300ms ease;
    }

    &-content {
        padding: 10px;
        position: relative;
        z-index: 20005;
        background-color: $modal_bg;
        font-weight: 400;
        max-height: 100%;
        font-size: 16px;

        @media only screen and (max-width: 768px) {
            overflow-y: scroll!important;
        }

        &.modal-content__animate-left {
            transform: translateX(-100%);
            transition: transform 300ms ease;
        }

        &.modal-content__animate-right {
            transform: translateX(100%);
            transition: transform 300ms ease;
        }

        &.modal-content__animate-fade {
            opacity: 0;
            transition: opacity 300ms ease;
        }

        @media (min-width: 996px) {
            padding: 40px;
        }
    }

    &-close {
        position: absolute;
        cursor: pointer;
        right: 24px;
        top: 24px;
        width: 20px;
        height: 20px;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 24px;
        background: var(--Rose-R20, #B80E9233);
        transition: background 0.3s ease;

        &:hover {
            background: var(--Rose-R60, #B80E9299);
        }
    }
}