@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
    overflow: hidden;
}
.section__variants{
    position: relative;
    overflow: hidden;
}
.section__variants__bg,.section__grid__bg{
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    right: 0;
    z-index: 0;
}

.text-info-root{
    padding: clamp(72px,6.6vw,128px) 15px 32px;
}

.text-info-title{
    margin-top: clamp(20px,2.6vw,50px);
}
.text-info-desc{ 
    max-width: 920px;
    margin: 0 auto;
    margin-top: 32px;
}


.variant-text-info-root{
    padding: 72px 15px 64px;
}

.variant-text-info-desc{
    max-width: 762px;
    margin: 0 auto;
    padding-top: 40px;
}

.actions{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: clamp(20px,2.6vw,50px);
}

.variants{
    margin-bottom: 80px;
}


.section__grid__bg{
    display: none;
    @media (min-width: 996px) {
        display: block;
        top: 50%;
        transform: translateY(-50%);
    }
}


.section__grid{
    position: relative;
    overflow: hidden;
    margin-top: 64px;
    @media (min-width: 1100px) {
        margin-bottom: -168px;
        margin-top: 84px;
    }
}

.zIndex{
    z-index: 4;
    padding-bottom: 20px;
    @media (min-width: 1100px) {
        padding-bottom: 0px;
    }
}

.row{
    display: flex;
}

.end{
    justify-content: center;
    @media (min-width: 768px) {
        justify-content: flex-end;
    }
    
}

.center{
    justify-content: center;
}
.gap{
    margin-left: 10px;
    margin-right: 10px;
}

.topY{
   
    @media (min-width: 1100px) {
        transform: translateY(-168px);
    }
}

.row__group__start{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
        gap: 0px;
    }
    @media (min-width: 1100px) {
        margin-bottom: -258px;
        display: block;
    }
}
.row__group__end{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    @media (min-width: 768px) {
        gap: 0px;
        justify-content: space-around;
        flex-direction: row;
    }
    @media (min-width: 1100px) {
        transform: translateY(-76px);
        display: block;
    }
}

.row__group__center{
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (min-width: 1100px) {
        justify-content: flex-start;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.section_title{
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 400;
    font-size: clamp(40px,3.33vw,64px);
    line-height: 100%;
    white-space: pre-line;
    color: $title;
}
.section_title_position{
    margin-bottom: 40px;
    @media (min-width: 1100px) {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.text-reviews-root{
    padding: 72px 15px 56px;
}

.reviews{
    margin-bottom: clamp(72px,9.27vw,178px);

}

.contacts{
    padding: 0px 0px 60px;
    position: relative;
    overflow: hidden;
    animation: bg-anim 60000ms ease infinite;
    -webkit-animation: bg-anim 60000ms ease infinite;
    background-repeat: repeat;
}

.contacts__decor{
    position: absolute;
    width: auto;
    height: auto;
    bottom: -214px;
    left: 0;
}

.banner__btn{
    min-width: 132px;
    height: 44px;
}

.images{
    padding-bottom: 30px;
    padding-top: 136px;
}


@-webkit-keyframes bg-anim {
0% {
    background-position-y: 0
}
to {
    background-position-y: -100%
}
}

@keyframes bg-anim {
0% {
    background-position-y: 0
}
to {
    background-position-y: -100%
}
}