.successModalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .button {
    border-radius: 16px;
    height: 60px;

    > span {
      border-radius: 16px;
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  cursor: default;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
  color: var(--Text-text-primary, #EBE9F0);
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: var(--Text-text-secondary, #EBE9F0CC);
}
