@import "../../../styles/variables";

.scheduleGrid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;



    @media (min-width: 1280px) {
        grid-template-columns: repeat(7, minmax(100px, 270px));
    }

    // @media (min-width: 1440px) {
    //     grid-template-columns: repeat(5, 1fr);
    // }

    // @media (min-width: 1530px) {
    //     grid-template-columns: repeat(6, 1fr);
    // }

    // @media (min-width: 1620px) {
    //     grid-template-columns: repeat(7, 1fr);
    // }
}

.scheduleGridItem {
    //margin-bottom: 15px;
    display: grid;
}

.header {
    padding-bottom: 15px;
}

.date {
    color: $title;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
    &__loader{
        height: 22px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 2px;
    }
    @media (min-width: 1280px) {
        font-size: 16px;
        font-weight: 500;
    }

}

.week {
    color: $text_data_40;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    font-variant-numeric: lining-nums proportional-nums;
    &__loader{
        height: 20px;
        width: 100%;
        border-radius: 12px;
    }
    @media (min-width: 1280px) {
        font-size: 20px;
        font-weight: 400;
    }
}

.events {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;

    @media (min-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    @media (min-width: 1280px) {
        grid-template-columns: repeat(1,  minmax(100px, 270px));
    }
}

.header{
    display: flex;
    align-items: center;
    column-gap: 5px;
    @media (min-width: 1280px) {
        flex-direction: column;
    }
}

.empty{
    display: flex;
    width: 100%;
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 80px auto;
    flex-direction: column;
    text-align: center;
    &__title{
        color:  $title;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: clamp(23px,2.4vw,46px);
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 1.38px;
        padding-bottom: 20px;
    }
    &__desc{
        color: rgba(235, 233, 240, 0.80);
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: clamp(14px,1.04vw,20px);
        font-weight: 400;
        padding-bottom: 40px;
    }
}

.reset{
    width: 220px;
    height: 44px;
    margin: 0 auto;
}