.stepper {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #EBE9F01A;
  border-radius: 24px;
  transition: border 0.3s ease-in-out;

  &:hover {
    border: 1px solid rgba(235, 233, 240, 0.2);
  }

  &.doubleTickets {
    padding: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  background: #C2D0FB1A;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out,
  opacity 0.3s ease-in-out;

  img {
    opacity: 0.6;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.value {
  width: 36px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  user-select: none;
  color: #EBE9F0;

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #EBE9F066;
  }
}