.phone-input {
  position: relative;
  padding-bottom: 20px;

  &__label {
    display: block;
    position: inherit;
    font-size: 14px;
    color: rgba(235, 233, 240, 0.6);
    font-weight: 400;
    margin-bottom: 5px;
    font-variant-numeric: lining-nums proportional-nums;

    left: 0;
    top: 0;
    background: transparent;
    padding: 0 2px;
    white-space: nowrap;
  }

  &__wrapper-placeholder {
    position: relative;
  }

  &__placeholder {
    font-family: "Inter Variable", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;

    position: absolute;
    top: 50%;
    left: 49px;
    transform: translateY(-50%);
    color: rgb(147, 143, 143, 0.5);
    pointer-events: none;
  }

  &__error {
    font-size: 13px;
    color: #fc4e4e;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 300ms ease;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    font-variant-numeric: lining-nums proportional-nums;

    &.error {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      transition: all 300ms ease;
    }
  }
}

.react-tel-input {
  &.phone-input__input-container {

  }


  & .flag-dropdown.phone-input__input-flag {
    background-color: transparent ! important;
    border: none !important;

    & .open {
      background: transparent !important;
      border-radius: 0 !important;
    }

    & .selected-flag {
      padding: 0 0 0 16px;

      & .arrow {
        border-top: 4px solid #ffffff;
        transition: transform 0.3s ease;
        position: absolute;
        top: 50%;

        &.up {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &:hover, &:focus {
        background-color: transparent;
        border: none;
      }
    }
  }

  & .form-control.phone-input__input {
    width: 100%;
    font-family: "Inter Variable", sans-serif;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: normal;
    background: rgba(194, 208, 251, 0.2);

    border: 1.5px solid rgba(235, 233, 240, 0.1);
    height: 40px;

    &.error {
      border-color: #fc4e4e;
    }
  }

  & .country-list {
    width: 350px !important;
    z-index: 1000 !important;
    backdrop-filter: blur(50px);
    background-color: rgba(194, 208, 251, 0.2);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: var(--Surface-surface-tertiary, rgba(194, 208, 251, 0.2)) !important;
      -webkit-backdrop-filter: blur(50px);
      backdrop-filter: blur(50px);
    }

    & .country {
      &.highlight, &:hover {
        background-color: var(--Surface-surface-tertiary, rgba(194, 208, 251, 0.1)) !important;
      }
    }

    & .dial-code {
      color: #999898 !important;
    }

    // scrollbar style
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(194, 208, 251, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(235, 233, 240, 0.2);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(235, 233, 240, 0.3);
    }
  }
}
