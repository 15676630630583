.contentWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;

  button.button {
    height: 60px;
    border-radius: 16px;
    border: 1.5px solid #B80E9299;

    &:hover {
      color: #B80E92;
      background: #B80E920D;
      border: 1.5px solid #B80E92;
    }
  }
}

.headingWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #EBE9F0;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #EBE9F0CC;
}