@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.form{
    margin-top: 20px;
}

.list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;
    
    @media (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media (min-width: 830px) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.list__item{
    width: 100%;
    display: flex;
    justify-content: center;
}

.sounds{
    display: flex;
    align-items: center;
    gap: clamp(13px,1.8vw,34px);
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    &__item{
        width: 74px;
        height: 74px;
        background-color:  rgba(194, 208, 251, 0.20);
        backdrop-filter: blur(50px);
        border-radius: 100%;
        transform: scale(1);
        transition: background-color 300ms ease, transform 300ms ease;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            padding: 16px;
        }
        @media (hover:hover) {
            &:hover{
                transform: scale(1.1);
                transition: background-color 300ms ease, transform 300ms ease;
                background-color:  rgba(194, 208, 251, 0.40);
            }
        }
    }
}

div.audio__loading{
    border-radius: 10px;
    background: linear-gradient(110deg, #c6965833 8%, #f5f5f5 18%, #c6965833 33%);
    box-shadow: 0px 0px 25px 0px rgba(198, 150, 87, 0.20);
    height: 61px;
    width: 100%;
    max-width: 900px;
    padding: 10px 20px;
    background-size: 200% 100%;
}