.section{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.list{
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    position: relative;
}
.list__item{
    height: 100%;
    position: absolute;
    z-index: -1;
    width: 10%;
    right: -10%;
    transition: all 1000ms ease;
    will-change: auto;
    overflow: hidden;
    padding-bottom: 128px;
    background-position: right
}
.list__item__null{
    z-index: 0;
    width: 77%;
    right: 23%;
}
.list__item__one{
    width: 77%;
    right: 23%;
    z-index: 1;
    .title{
        opacity: 1;
        transition: opacity 1000ms ease;
    }
}
.list__item__two{
    width: 18%;
    right: 5%;
    z-index: 2;
    .image{
        max-width: 352px;
        max-height: 224px;
    }
}
.list__item__three{
    width: 5%;
    right: 0;
    z-index: 3;
    .image{
        max-width: 104px;
        max-height: 80px;
    }
}

.list__item__one__big{
    width: 77%;
    right: 23%;
    z-index: 1;
    .title{
        opacity: 1;
        transition: opacity 300ms ease;
    }
}
.list__item__two__big{
    width: 23%;
    right: 0%;
    z-index: 2;
    .image{
        max-width: 500px;
        max-height: 324px;
    }
}

.list__item__one__full{
    width: 100%;
    right: 0%;
    z-index: 1;
    .title{
        opacity: 1;
        transition: opacity 1000ms ease;
    }
    .image{
        transform: translateX(-64px);
    }
}

.image{
    position: absolute;
    right: 0px;
    bottom: 58px;
    // width: clamp(,,632px);
    // height: clamp(,,464px);
    object-fit: cover;
    object-position: center;
    transition: all 800ms ease;
    max-width: 632px;
    max-height: 464px;
    width: 100%;
    height: 100%;
    transform: translateX(0px);
    // @media (min-width: 1580px) {
    //     max-width: 632px;
    //     max-height: 464px;
    //     width: 100%;
    //     height: 100%;
    // }
}

.title{
    position: absolute;
    left: 64px;
    bottom: 120px;
    width: 268px;
    font-size: clamp(18px,1.6vw,32px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
    opacity: 0;
    transition: opacity 300ms ease;
    @media (min-width: 1440px) {
      width: clamp(240px,26.6vw,512px);  
    }
}

.sectionTitle{
    position: absolute;
    left: 64px;
    top: 128px;
    font-size: clamp(32px,5vw,96px);
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 2.88px;
    z-index: 4;
}