.swiper{
    height: 700px;
}

.swiper__slide{
    width: 100%;
    position: relative;
    height: 100%;
    border-radius: 24px;
}

.swiper__slide__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
    overflow: hidden;
}

.section {
    position: relative;
    overflow: hidden;
}
.navigation__btn {
    width: 96px;
    position: absolute;
    z-index: 6;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.01);
    backdrop-filter: blur(16px);
    cursor: pointer;
    height: 72px;
    padding: 26px 0px 26px 26px;
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
    @media (hover: hover) {
        &:hover{
            opacity: 0.8;
        }
    }

}

.navigation__btn__next {
    right: 0px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 0px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}
