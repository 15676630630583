@import "../../../styles/variables";

.description {
  color: rgba(235, 233, 240, 0.80);
  font-size: 0.875em;
  font-weight: 400;
  line-height: 17px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: max-height 0.8s ease, min-height 0.8s ease;

  &.open {
    -webkit-line-clamp: none;

    @supports (-webkit-touch-callout: none) {
      transition: none;
      overflow: visible !important;
    }
  }

  @media (max-width: 767px) {
    display: block;
    max-height: 100% !important;

    -webkit-line-clamp: none;
    overflow: inherit;
    -webkit-overflow-scrolling: touch;
  }
}

.button {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-decoration: none;

  color: var(--Button-link_default, #4080FFCC);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  transition: color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover {
    color: rgba(64, 128, 255, 1);

    &::after {
      transform: scaleX(1);
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}
