@import "../../../styles/variables";

.content {
  margin: 0 10px;
  padding: 0;
  border-radius: 24px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    overflow-y: auto!important;
    max-width: 600px;
    width: 100%;
  }
}

.contentWithoutAnImage {
  max-width: 600px;
  width: 100%;
}

.eventContent {
  display: flex;
  width: 100%;
  max-width: 900px;
  max-height: 583px;

  @media (max-width: 768px) {
    max-width: 600px;
  }

  &__image, &__info {
    flex: 1;
  }

  &__image {
    display: flex;
    position: relative;
    height: 583px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}
