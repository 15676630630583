@import "../../../../styles/variables";

.about {
  &Header {
    display: flex;
    align-items: center;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(14px, 0.93vw, 18px);
    font-weight: 700;
    padding-bottom: 12px;

    svg {
      margin-right: 15px;
    }
  }
}