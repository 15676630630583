.card__post{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
    }
    &__info{
        position: absolute;
        width: 100%;
        z-index: 2;
        color: #fff;
        font-weight: 500;
        font-size: clamp(16px,1.25vw,24px);
        line-height: 100%;
        letter-spacing: 0.03em;
        text-align: left;
        bottom: 0px;
        left: 0px;
        padding: 60px 16px 16px;
    }
}