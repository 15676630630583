.flter__item__input{
    @media (min-width: 575px) {
        min-width: 167px;
        width: 100%;
    }
}
.filter__item__input__skeleton{
    width: 100%;
    height: 40px;
    border-radius: 20px;
    min-width: 130px;
}
.filters{
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    @media (min-width: 1280px) {
        justify-content: center;
        
    }
}
.padding{
    padding-bottom: 22px;
}

.filter__item{
    width: 100%;
    @media (min-width: 768px) {
        width: auto;
    }
}

.flter__item__group{
    padding-bottom: 22px;
    width: auto;
    @media (max-width: 575px) {
        width: 47%;
    }

}

.input_date{
    caret-color: transparent;
    border-radius: 40px!important;
}

.filter__item__btn__group{
    @media (max-width: 575px) {
        width: 100%;
        & > div {
            width: 100%;
        }
    }
}

.clear{
    opacity: 1;
    transition: opacity 300ms ease;
    cursor: pointer;
    &:hover{
        transition: opacity 300ms ease;
        opacity: 0.8;
    }
}