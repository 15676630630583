.group {
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
  border-radius: 40px;
  border: 1px solid transparent;
  background: rgba(194, 208, 251, 0.10);
  backdrop-filter: blur(50px);
  overflow-x: auto;
  white-space: nowrap;
  padding: 4px;
  width: min-content;
}

.columnOff {
  flex-wrap: nowrap;
}

.group__item__load {
  min-width: 120px;
}

.group__item {
  color: rgba(235, 233, 240, 0.40);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-weight: 500;
  padding: 0 16px;
  background-color: transparent;
  border-radius: 96px;
  transition: background-color 300ms ease, color 300ms ease;
  cursor: pointer;
  border: none;
  text-align: center;
  width: 100%;
  min-height: 32px;

  &[type=button] {
    transition: background-color 300ms ease, color 300ms ease;
    background-color: transparent;
  }

  @media (min-width: 768px) {
    width: auto;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    &:hover {
      transition: background-color 300ms ease, color 300ms ease;
      color: #fff;

      &[type=button] {
        transition: background-color 300ms ease, color 300ms ease;
        background-color: rgba(194, 208, 251, 0.20);
      }
    }
  }

}

.active {
  transition: background-color 300ms ease, color 300ms ease;
  background-color: rgba(194, 208, 251, 0.20);
  color: #EBE9F0;

  &[type=button] {
    transition: background-color 300ms ease, color 300ms ease;
    background-color: rgba(194, 208, 251, 0.20);
  }
}
