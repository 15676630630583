@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.form{
    margin-top: 20px;
}


.paper{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(198, 150, 87, 0.20);
}


.list__item{
    width: 100%;
    @media (min-width: 600px) {
        width: calc(50% - 10px);
    }
    @media (min-width: 830px) {
        width: 100%;
    }
    @media (min-width: 1280px) {
        max-width: 760px;
    }
}

.list__item__loading{
    height: 72px;
    border-radius: 10px;
}


.list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    
    @media (min-width: 600px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media (min-width: 830px) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.list__item{
    width: 100%;
    @media (min-width: 600px) {
        width: calc(50% - 10px);
    }
    @media (min-width: 830px) {
        width: 100%;
    }
}


.list__empty {
    display: flex;
    width: 100%;
    max-width: 740px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 80px auto;
    flex-direction: column;
    text-align: center;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(23px, 2.4vw, 46px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.38px;
    padding-bottom: 20px;
}