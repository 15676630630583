@import "../../styles/_variables.scss";

.container {
    position: relative;
    height: 100%;
    width: 100%;
}

.overflow {
    overflow: hidden;
}

.slider {
    height: 100%;
    width: calc(100% + 15px);
    @media (min-width: 680px) {
        width: 100%;
    }
}

.slide {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    
}

.img__wrap{
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    img{
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
}

.slide__preview,
.slide__dot__preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.navigation__btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 200;
    background-color: rgba(198, 150, 87, 0.2);
    -webkit-backdrop-filter: blur(7.5px);
    backdrop-filter: blur(7.5px);
    transition: background-color 300ms ease;
    width: 44px;
    height: 40px;
    transform: translateY(120%);
    padding: 10px;

    svg {
        width: 100%;
        height: 100%;
    }
    @media (min-width: 768px) {
        padding: 15px;    
        width: 60px;
        height: 50px;
        transform: translateY(-50%);
    }
    @media (min-width: 1520px) {
        width: 90px;
        height: 80px;
        padding: 25px;
    }

    &:hover {
        transition: background-color 300ms ease;
        background-color: rgba(198, 150, 87, 0.3);
    }
}

.prev {
    left: 0px;
    border-radius: 0px 40px 40px 0px;
    svg {
        transform: rotate(180deg);
    }
}

.next {
    right: 0px;
    border-radius: 40px 0px 0px 40px;
}

.navigation__dots {
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    z-index: 200;
}

.navigation__dot {
    width: clamp(8px,5.7vw,110px);
    height: 4px;
    background-color: $sad_light;
    border-radius: 1px;
    transition: background-color 300ms ease;
}

.active {
    background-color: $sad;
    transition: background-color 300ms ease;
}

.dots__image {
    margin-top: 15px;
    height: 113px;
    overflow: hidden;
}

.slide__dot {
    height: 113px;
}

.slide__dot__preview {
    border-radius: 10px;
    overflow: hidden;
    padding: 4px;
    border: 1.5px solid  rgba(235, 233, 240, 0.40);
    transition: border-color 300ms ease;
    border-color: transparent;
    cursor: pointer;
}
.dot_active{
    transition: border-color 300ms ease;
    border-color: rgba(235, 233, 240, 0.40);
}
.vertical{
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    .dots__image{
        height: auto;
        margin-top: 0px;
        max-width: 100px;
    }
    .slide__dot{
        height: auto;
    }
}