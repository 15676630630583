@import "../../styles/_variables.scss";

.container{
    position: relative;
}

.navigation__btn {
    position: absolute;
    z-index: 6;
    top: 50%;
    transform: translateY(-50%);
    background: $surface_light;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
    backdrop-filter: blur(7.5px);
    cursor: pointer;
    width: 60px;
    height: 60px;
    padding: 14px;
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
    @media (min-width: 1280px) {
        padding: 24px;
        width: 140px;
        height: 80px;
        &:hover{
            opacity: 0.8;
        }
    }

}

.navigation__btn__next {
    right: -10px;
    border-radius: 80px 0px 0px 80px;
    @media (min-width: 1280px) {
        right: -50px;
    }
    @media (min-width: 1921px) {
        right: 0px;
    }
}

.navigation__btn__prev {
    left: -15px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
    @media (min-width: 1280px) {
        left: -50px;
    }
    @media (min-width: 1921px) {
        left: 0px;
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}