@import "../../styles/variables";

.page {
  min-height: calc(100vh - 408px);
}

.form {
  margin-top: 20px;
}

.tab {
  margin: 0px;
}

.tab__item {
  //width: auto;
}

.paper {
  border-radius: 24px;
  background: rgba(194, 208, 251, 0.20);
  //backdrop-filter: blur(50px);
}