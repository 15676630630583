@import "../../../styles/variables";

.header{
    position: relative;
    padding-bottom: 20px;
}
.title{
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.72px;
    text-align: center;
}

.body{
    padding-top: 8px;
    padding-bottom: 8px;
}

.actions{
    position: relative;
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.hr{
    position: absolute;
    left: -17px;
    width: calc(100% + 32px);
    background-color: rgba(235, 233, 240, 0.20);
}
.hr_bottom{
    bottom: 0;
}
.hr_top{
    top: 0;
}
.btn{
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    max-width: 320px;
    height: 44px;
}

.mg15{
    margin-bottom: 16px;
}