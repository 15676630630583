.input-select-form-control{
    position: relative;
}
.input-select-form-control {
    position: relative;
    &.padding{
        padding-bottom: 0px;
    }
    div[class$="-placeholder"] {
        color: #969BA3;
    }
    &.error div[class$="-control"] {
        border-color: #FC4E4E;
    }
    &__error {
        font-size: 13px;
        color: #FC4E4E;
        position: absolute;
        width: 100%;
        bottom: 6px;
        left: 0;
        transition: all 300ms ease;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        &.error {
            opacity: 1;
            visibility: visible;
            z-index: 1;
            transition: all 300ms ease;
        }
    }
    &-adornment {
		position: absolute;
		z-index: 6;
		top: 50%;
		transform: translateY(-46%);
		font-size: 15px;
		color: rgba(235, 233, 240,  0.4);
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgba(150, 155, 163, 1);
		font-weight: 500;
		transition: color 300ms ease;

		&.active {
			color: rgba(235, 233, 240,  0.4);
			font-weight: 400;
		}

		&__end {
			right: 14px;
		}

		&__start {
			left: 20px;
		}
	}
}