@import "src/styles/_variables.scss";

.container{
    margin: 0 auto;
    position: relative;
    @media (min-width: 996px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.grid{
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    @media (min-width: 996px) {
        display: grid;
        grid-template-columns: 1fr clamp(198px,15.52vw,298px) 1fr;
        grid-template-rows: auto;
        grid-template-rows: auto;
        align-items: center;
    }
    @media (min-width: 1280px) {
        gap: clamp(60px,10.3vw,198px);
    }
}
.title{
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(28px, 1.8vw, 36px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    @media (min-width: 996px) {
        text-align: left;
    }
}
.preview{
    width: 330px;
    height: 330px;
    border-radius: 100%;
    margin: 0 auto;
    @media (min-width: 996px) {
        width: clamp(200px,15.52vw,300px);
        height: clamp(200px,15.62vw,300px);
    }
}
.info{
    text-align: center;
    @media (min-width: 996px) {
        text-align: left;
    }

}
.info__name{
    margin-bottom: 16px;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(32px, 2.4vw, 46px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
}
.info__desc{
    margin-bottom: 32px;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-weight: 400;
}

.contacts{
    display: flex;
    flex-direction: column;
    gap: clamp(16px,1.25vw,24px);
    a{
        color: $title;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 17;
        font-weight: 500;
    }
}