@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.cart__list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    li{
        width: 100%;
    }
}
.title{
    padding-bottom: 30px;
    @media (min-width: 1280px) {
        padding-bottom: 60px;
    }
}
.aside{
    order: 40;
    @media (min-width: 1280px) {
        order: 0;  
    }
}