.feedbackSection {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.imageWrapper {
  justify-content: center;
  max-width: 104px;
  max-height: 104px;
  width: 104px;
  height: 104px;
  margin: 72px auto 32px;

  @media (min-width: 768px) {
    margin: 0 auto 24px;
    max-width: 140px;
    max-height: 140px;
    width: 140px;
    height: 140px;
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-size: cover;
  mask-repeat: no-repeat;
}

.videoSuccess {
  mask-image: url('./assets/order-complete.png');
  -webkit-mask-image: url('./assets/order-complete.png');
}

.videoError  {
  mask-image: url('./assets/order-error.png');
  -webkit-mask-image: url('./assets/order-error.png');
}

.title {
  font-size: clamp(28px, 2vw, 36px);
  font-weight: 500;
  line-height: clamp(28px, 2vw, 36px);
  letter-spacing: 0.03em;
  margin-bottom: 16px;
}

.description {
  font-size: clamp(14px, 2vw, 20px);
  font-weight: 400;
  line-height: clamp(17px, 2vw, 24px);
}

.buttonsWrapper {
  margin-top: 32px;
  margin-bottom: 72px;
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 24px;
  }
}

.buttonLink {
  max-height: 44px;
  padding: 12px 24px;
}
