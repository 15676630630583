@import "../../../styles/variables";

.eventContentInfo {
  padding: 68px 24px 24px;
  position: relative;

  &:before {
    background: linear-gradient(180deg, #33b3ac33, #33b3ac00);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 1s ease;
    width: 100%;
    z-index: -1;
  }

  &__active {
    .tagAndBookingCountWrap {
      visibility: visible;
    }

    &:before {
      opacity: 1;
    }
  }
}

.title {
  color: var(--Text-text-primary, #EBE9F0);
  font-size: clamp(28px, 1.8vw, 36px);
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 1.08px;
  padding-bottom: 12px;
}

.tagAndBookingCountWrap {
  visibility: hidden;
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: default;
}

.tag {
  background-color: rgba(51, 179, 172, 0.6);
  padding: 8px 16px;
  min-width: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(51, 179, 172, 0.5);
  }

  svg {
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
}

.bookingsCount {
  display: flex;
  align-items: center;
  gap: 4px;
}

.event__info {
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.buttonWrapper {
  margin-top: 20px;
}

.stepperMobile {
  margin-top: 24px;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;

    > div {
      height: 40px;
    }
  }
}
