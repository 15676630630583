@import "src/styles/_variables.scss";

.section {
    position: relative;
    overflow: hidden;
}
div.slide{
    height: auto;
}
.swiper__card__info {
    &__slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        border-radius: 24px;
        background: rgb(47,7,88);
        background: linear-gradient(0deg, rgba(47,7,88,1) 39%, rgba(1,0,2,1) 96%);
        position: relative;

        &__img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            position: relative;
            z-index: 2;
            height: 100%;
        }

        .small{
            height: clamp(372px,20.31vw,390px);
        }

        &__footer {
            padding-top: clamp(16px, 1.25vw, 24px);
            padding-bottom: clamp(16px, 1.25vw, 24px);
            padding-left: clamp(16px, 1.25vw, 24px);
            padding-right: clamp(16px, 1.25vw, 24px);
            position: relative;
            z-index: 2;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        &__title {
            color: $title;
            font-size: clamp(22px, 1.8vw, 36px);
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 1.08px;
            margin-bottom: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-variant-numeric: lining-nums proportional-nums;
            flex-grow: 1;
        }

        &__subtitle {
            color: $primary_text__light;
            font-size: clamp(12px, 0.83vw, 16px);
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
.slide_default{
    max-height: 439px;
    aspect-ratio: 1/3;
}
.circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    transform: rotate(160deg);
}

.navigation__btn {
    display: none;
    width: 68px;
    position: absolute;
    z-index: 6;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(194, 208, 251, 0.20);
    backdrop-filter: blur(16px);
    cursor: pointer;
    height: 52px;
    padding: 16px;
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
    @media (min-width: 996px) {
        display: block;
        height: 80px;
        padding: 24px;
        width: 140px;
    }
    @media (hover: hover) {
        &:hover{
            opacity: 0.8;
        }
    }
    @media (min-width: 1530px) {
        display: none;
    }
}

.navigation__btn__next {
    right: 0px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 0px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}

.transparent{
    background-color: transparent;
}

.absolute{
    bottom: 0px;
    left: 0px;
    position: absolute;
    .swiper__card__info__slide__title{
        text-align: left;
        display: inline;
        font-size: clamp(16px, 1.25vw, 24px);
        overflow: hidden;
        width: 100%;
        height: auto;
    }
}

.slide__bg{
    position: absolute;
    z-index: 1;
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}