$padding: 20;

.input-form-control {
  position: relative;
  padding-bottom: 20px;

  &__label {
    display: block;
    font-size: 14px;
    color: rgba(235, 233, 240, 0.60);
    font-weight: 400;
    margin-bottom: 5px;
    font-variant-numeric: lining-nums proportional-nums;
  }

  &__wrapper-input {
    width: 100%;
    position: relative;

    &.error {
      & .input-form-control__input {
        border-color: #fc4e4e;
        transition: border-color 300ms ease;
      }

      & .input-form-control__btn:hover {
        border-color: #fc4e4e;
      }
    }
  }

  &-adornment {
    position: absolute;
    z-index: 6;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: rgba(235, 233, 240, 0.4);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(150, 155, 163, 1);
    font-weight: 500;
    transition: color 300ms ease;

    &.active {
      color: rgba(235, 233, 240, 0.4);
      font-weight: 400;
    }

    &__end {
      right: $padding * 1px;
    }

    &__start {
      left: $padding * 1px;
    }
  }

  &__input {
    height: 40px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    padding-right: $padding * 1px;
    padding-left: $padding * 1px;
    transition: border-color 300ms ease;
    font-family: inherit;
    resize: none;
    font-variant-numeric: lining-nums proportional-nums;

    &.offset_left {
      padding-left: $padding + 20 * 1px;
    }

    &.offset_right {
      padding-right: $padding + 20 * 1px;
    }

    &.default {
      border: none;
      background: rgba(194, 208, 251, 0.20);
      color: #EBE9F0;
      border-radius: 8px;
      border: 1.5px solid rgba(235, 233, 240, 0.10);
    }

    &::placeholder {
      color: rgba(235, 233, 240, 0.80);
      font-weight: 500;
      font-variant-numeric: lining-nums proportional-nums;
    }

    &:focus,
    &:focus-visible {
      outline: 0px solid rgba(194, 208, 251, 0.20);
      outline-color: rgba(194, 208, 251, 0.20)
    }
  }

  &__error {
    font-size: 13px;
    color: #fc4e4e;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
    transition: all 300ms ease;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    font-variant-numeric: lining-nums proportional-nums;

    &.error {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      transition: all 300ms ease;
    }
  }
}

textarea.input-form-control__input {
  padding-top: 20px;
  padding-bottom: 20px;
}

.input-calendar {

  width: 100%;

  &-adornment__end, &-adornment__start {
    z-index: 0;
    width: 15px;
    color: rgba(235, 233, 240, 0.4);
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  @media (min-width: 575px) {
    max-width: 270px;
  }
}

.input-calendar {
  .react-datepicker__close-icon {
    right: 10px;

    &::after {
      background-color: rgba(194, 208, 251, 0.1);
    }
  }

  .react-datepicker__triangle {
    display: none;
  }
}

:root {
  --calendar-bg: rgb(36, 29, 50);
}

@supports (backdrop-filter: blur(50px)) {
  :root {
    --calendar-bg: transparent;
  }

  .react-datepicker.input-form-calendar {
    backdrop-filter: blur(50px);
  }
}


.react-datepicker.input-form-calendar {
  border-radius: 10px;
  color: rgba(235, 233, 240, 0.80);
  background: var(--calendar-bg);
  border: 1px solid transparent;
  font-variant: normal;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 500;

  .react-datepicker__month {
    margin: 0;
    text-align: center;
    background-color: rgba(194, 208, 251, 0.1);
    padding: 0.4rem;
  }

  .react-datepicker__header {
    background-color: rgba(194, 208, 251, 0.10);
    border: none;
  }

  .input-calendar-header-date {
    color: rgba(235, 233, 240, 0.6);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 600;

  }

  .react-datepicker__day-names {
    margin-top: 10px;

    .react-datepicker__day-name {
      color: rgba(235, 233, 240, 0.80);
      font-size: 14px;
      font-weight: 500;
    }
  }

  .react-datepicker__week {

    .react-datepicker__day {
      color: rgba(235, 233, 240, 0.80);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--range-end {
    border-radius: 100% !important;
    background-color: rgba(235, 233, 240, 0.20) !important;
    color: rgba(235, 233, 240, 0.80) !important;
    position: relative;
    transition: background-color 100ms ease, color 100ms ease;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background-color: rgba(198, 150, 87, 0.2);
      left: 0;
      top: 0;
      transition: opacity 100ms ease, border-radius 100ms ease;
    }
  }

  .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month {
    &:before {
      background-color: rgba(235, 233, 240, 0.2);
      border-radius: 100% 0 0 100%;
    }
  }

  .react-datepicker__day {
    &.react-datepicker__day--keyboard-selected::before {
      background-color: rgba(235, 233, 240, 0.2);
      border-radius: 100% 0 0 100%;
      display: block;
    }


    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
      &.react-datepicker__day--range-start,
      &.react-datepicker__day--selecting-range-start {
        border-radius: 100% !important;
        background-color: rgba(235, 233, 240, 0.20) !important;
        color: rgba(235, 233, 240, 0.80) !important;
        position: relative;
        transition: background-color 100ms ease, color 100ms ease;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(235, 233, 240, 0.20);
          border-radius: 100% 0 0 100%;
          transition: opacity 100ms ease, border-radius 100ms ease;
          left: 0;
          top: 0;
        }
      }

      &.react-datepicker__day--range-end,
      &.react-datepicker__day--selecting-range-end {
        border-radius: 100% !important;
        background-color: rgba(235, 233, 240, 0.20) !important;
        color: rgba(235, 233, 240, 0.80) !important;
        position: relative;
        transition: background-color 100ms ease, color 100ms ease;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(235, 233, 240, 0.20);
          border-radius: 0 100% 100% 0;
          transition: opacity 100ms ease, border-radius 100ms ease;
          left: 0;
          top: 0;
        }
      }
    }
  }

  // .react-datepicker__day.react-datepicker__day--selected.custom.react-datepicker__day--keyboard-selected.react-datepicker__day--today{
  // 	background-color: transparent!important;
  // 	color: rgba(235, 233, 240, 0.80)!important;
  // 	&::before{
  // 		border-radius: 0%!important;
  // 	}
  // }

  .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--today:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range):not(.custom) {
    background-color: transparent;
  }

  .react-datepicker__day--selected {
    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--in-range {
      border-radius: 0%;
      background-color: rgba(235, 233, 240, 0.20);
      transition: border-radius 100ms ease, background-color, 300ms ease;
    }

    &.react-datepicker__day--range-start.react-datepicker__day--selected.react-datepicker__day--in-range {
      border-radius: 100% !important;
      background-color: rgba(235, 233, 240, 0.20) !important;
      color: rgba(235, 233, 240, 0.80) !important;
      position: relative;
      transition: background-color 100ms ease, color 100ms ease;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(235, 233, 240, 0.20);
        display: block;
        border-radius: 100% 0% 0% 100% !important;
        opacity: 1;
        transition: opacity 100ms ease, border-radius 100ms ease;
        left: 0;
        top: 0;
      }
    }
  }

  .custom.react-datepicker__day--selected {
    background-color: rgba(235, 233, 240, 0.20);
    border-radius: 0 !important;
    position: relative;

    &:not(:hover).react-datepicker__day--today:before {
      background: transparent;
    }


    &:first-child {
      border-radius: 100% !important;
      background-color: rgba(235, 233, 240, 0.20) !important;
      color: rgba(235, 233, 240, 0.80) !important;
      position: relative;
      transition: background-color 100ms ease, color 100ms ease;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(235, 233, 240, 0.20);
        display: block;
        border-radius: 100% 0% 0% 100% !important;
        opacity: 1;
        transition: opacity 100ms ease, border-radius 100ms ease;
        left: 0;
        top: 0;
      }
    }

    &:last-child {
      border-radius: 100% !important;
      background-color: rgba(235, 233, 240, 0.20) !important;
      color: rgba(235, 233, 240, 0.80) !important;
      position: relative;
      transition: background-color 100ms ease, color 100ms ease;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        border-radius: 0% 100% 100% 0%;
        opacity: 1;
        transition: opacity 100ms ease, border-radius 100ms ease;
        left: 0;
        top: 0;
        background-color: rgba(235, 233, 240, 0.20);
      }
    }
  }

  //.react-datepicker__day--keyboard-selected,
  //.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  //	&::before {
  //		opacity: 0;
  //		transition: opacity 100ms ease, border-radius 100ms ease;
  //	}
  //}
  //
  //.react-datepicker__day--keyboard-selected.react-datepicker__day--range-start.react-datepicker__day--in-range,
  //.react-datepicker__day--keyboard-selected {
  //	&::before {
  //		display: block;
  //		border-radius: 100% 0% 0% 100%;
  //		opacity: 1;
  //		transition: opacity 100ms ease, border-radius 100ms ease;
  //	}
  //}
  //
  //.react-datepicker__day--range-end.react-datepicker__day--in-range {
  //	&::before {
  //		display: block;
  //		border-radius: 0% 100% 100% 0%, border-radius 100ms ease;
  //		opacity: 1;
  //		transition: opacity 100ms ease, border-radius 100ms ease;
  //	}
  //}

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    line-height: 25px;
    margin: 0px;
    padding: 0.157rem;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0%;
    background-color: rgba(235, 233, 240, 0.20);
    transition: border-radius 100ms ease, background-color, 300ms ease;
  }

  .react-datepicker__week {
    border-radius: 14px 14px 14px 14px;
  }
}


@media(hover: hover) {
  .react-datepicker__day:hover {
    background-color: rgba(25, 25, 25, 0.8) ! important;
  }

  //.react-datepicker__week{
  //	&:hover{
  //		background-color: rgba(235, 233, 240, 0.20);
  //		border-radius: 14px 14px 14px 14px;
  //		.react-datepicker__day{
  //			&:first-child{
  //				border-radius: 100%;
  //				background-color: rgba(235, 233, 240, 0.20);
  //				color: rgba(235, 233, 240, 0.80);
  //			}
  //			&:last-child{
  //				border-radius: 100%;
  //				background-color: rgba(235, 233, 240, 0.20);
  //				color: rgba(235, 233, 240, 0.80);
  //			}
  //		}
  //	}
  //}
}
