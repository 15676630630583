.box {
    padding-top: 40px;
    @media screen and (min-width: 1280px) {
        padding-top: 140px;
    }
}

.video {
    width: 100%;
    height: auto;
    transform: scale(1.1);
}
.video__container{
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
}
.video__wrap {
    overflow: hidden;
    height: 100vh !important;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: #1c1c1c;
    z-index: 199;

    &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 2;
    }
}