@import "../../../styles/variables";

.card{
    border-radius: 24px;
    background: rgba(194, 208, 251, 0.10);
    max-height: 480px;
    width: 100%;
    display: flex;
    overflow: hidden;
    backdrop-filter: blur(50px);
    @media (min-width:680px) {
        flex-direction: column;
    }
    
}

.preview{
    object-fit: cover;
    object-position: center;
    width: 120px;
    //aspect-ratio: 16 / 18;
    height: 150px;
    @media (min-width:680px) {
        width: 100%;
        height: 330px;
    }
}
.header__title{
    overflow: hidden;
    color: $title;
    font-weight: 500;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 17px;
    text-overflow: ellipsis;
    margin-top: 16px;
}

.tth{
    &__colors,&__sizes{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &__title{
            color: $purple_light;
            font-size: 14px;
            font-weight: 500;
            margin-right: 5px;
        }
        &__row{
            display: flex;
            align-items: center;
            gap: 3px;
        }
    }
}

.tth__colors__row{
    span{
        width: 19px;
        height: 19px;
        border-radius: 20px;
        border: 1px solid $sad_light;
    }
}

.tth__sizes__row{
    span{
        font-size: 12px;
        font-weight: 400;
        font-variant-numeric: lining-nums proportional-nums;
        color: rgba(235, 233, 240, 0.60);
    }
}
.tth__sizes{
    margin-top: 8px;
    display: flex;
    gap: 3px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
}
.tth__sizes__item{
    background: rgba(194, 208, 251, 0.10);
    border-radius: 4px;
    padding: 4px 8px;
}

.tth__sizes__row{
    gap: 5px;
}

.price{
    margin-top: 16px;
}
.price,.currency{
    color:  $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
}
.currency{
    letter-spacing: 1.08px;
    font-size: 17px;
    margin-right: 7px;
    display: inline-block;
    color: rgba(235, 233, 240, 0.60);
}

.btn{
    width: 100%;
    @media (min-width: 860px) {
        width: auto;
    }
}

.body{
    padding: 8px 16px 16px 16px;
}

.exept{
    margin-top: 8px;
}