@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
    overflow: hidden;
}

.title{
    color: $title;
    text-align: center;
    font-size: clamp(28px, 3.33vw, 64px);
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    padding-bottom: clamp(40px,3.33vw,64px);
}
.masters{
    padding-bottom: 0px;
    @media (min-width: 1280px) {
        padding-top: 77px;
    }
}

.section__start{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.bg{
    position: absolute;
    z-index: 0;
    top: -20%;
    height: auto;
}


.section__start__content, .section__who__content{
    position: relative;
    z-index: 1;
    padding-top: 64px;
    padding-bottom: 64px;
}

.text__block__preview{
    position: relative;
    padding-top: 40px;
    @media (min-width: 1280px) {
        padding-top: 0px;    
    }
}
.slide__start{
    height: auto;
}

.banner__btn{
    height: 60px;
    min-width: 220px;
}
.gallery{
    padding-top: 64px;
    padding-bottom: 72px;
}