@import "../../../styles/_variables.scss";

.section {
    position: sticky;
    top: 0;
    overflow: hidden;
}

.bg {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
}

.row {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 3;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.colLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 1000ms ease;
    padding-right: 30px;
    @media screen and (min-width: 768px) {
        width: 31%;
    }
}

.colRight {
    width: 100%;
    padding-top: 20px;
    position: relative;
    //overflow: hidden;
    padding-bottom: 95px;
    transform: translateX(100%);
    transition: transform 1000ms ease;
    @media screen and (min-width: 768px) {
        width: 69%;
        padding-left: 22px;
    }
}

.container {
    //overflow: hidden;
    margin-left: 15px;

    @media screen and (min-width: 768px) {
        margin-left: 30px;
    }

    @media screen and (min-width:1280px) {
        margin-left: 50px;
    }
}



.circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    transform: rotate(160deg);
}


.viewText,.viewSlider{
    transform: translateX(0px);
}

.slide__list{
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    white-space: nowrap;
}

.slide{
    width: 500px;
    min-width: 500px;
    max-width: 500px;
    align-items: stretch;
}