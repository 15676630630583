@import "../../../styles/variables";

.list{
    display: flex;
    gap: 60px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    @media (min-width: 980px) {
        gap: 20px;
        flex-direction: column;
        justify-content: flex-start;
    }
    li{
        color: rgba(235, 233, 240, 0.80);
        transition: background-color 300ms ease, color 300ms ease;
        border-radius: 10px;
        svg{
            margin-right: 0px;
            margin-bottom: 5px;
            stroke-opacity: 0.6;
            transition: stroke-opacity 300ms ease;
            @media (min-width: 980px) {
                margin-right: 10px;
                margin-bottom: 0px;
            }
        }
        a{
            align-items: center;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            color: rgba(235, 233, 240, 0.80);
            padding: 0px;
            flex-direction: column;
            @media (min-width: 980px) {
                font-size: 17px;
                flex-direction: row;
                padding: 14px 20px;
                color: rgba(235, 233, 240, 0.80);
            }
        }
        @media (min-width: 980px) {
            color: rgba(235, 233, 240, 0.80);
            &:hover{
                background-color: rgba(194, 208, 251, 0.10);
                transition: background-color 300ms ease ;
                a{
                    color: #EBE9F0;
                }
                svg{
                    transition: stroke-opacity 300ms ease;
                    stroke-opacity: 1;
                }
            }
        }

    }
}

li.active{
    color: #EBE9F0;
    transition: background-color 300ms ease, color 300ms ease;
    a{
        color: #EBE9F0;
    }
    svg{
        transition: stroke-opacity 300ms ease;
        stroke-opacity: 1;
    }
    @media (min-width: 980px) {
        background-color: rgba(194, 208, 251, 0.10);
        transition: background-color 300ms ease, color 300ms ease;
        a{
            color: #EBE9F0;
        }
        svg{
            transition: stroke-opacity 300ms ease;
            stroke-opacity: 1;
        }
    }

}