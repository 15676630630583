.slider {
  position: relative;
  display: flex;
  height: 100%;
  max-height: 1000px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: transform 500ms ease;
  user-select: none;

  @media only screen and (max-width: 1279px) {
    display: none;
  }

  &_col {
    flex-direction: column;
  }

  &_row {
    flex-direction: row;
  }

  &__item {
    //position: relative;
    transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1);;
    flex: 0;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    padding: 128px 0 128px 64px;

    &_is_hide {
      padding: 0;
      opacity: 0;

      > div {
        display: none
      }
    }

    &_is_active {
      flex: 4;
    }

    &_is_next {
      flex: 1;

      > div {
        display: none
      }
    }

    &_is_next_next {
      flex: 0.25;

      > div {
        display: none
      }
    }

    &_is_last &_image_container {
      right: 104px;
    }

    &_title {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;

      font-size: clamp(48px, 4vw, 96px);
      font-weight: 400;
      line-height: 96px;
      letter-spacing: 0.03em;
      text-align: left;
    }

    &_content {
      position: relative;
      height: 744px;
      max-height: 904px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media only screen and (max-width: 1680px) {
        max-height: 600px;
      }

      @media only screen and (max-width: 1440px) {
        max-height: 500px;
      }
    }

    &_image {
      width: 100%;
      height: 100%;
      transform: scale(0.4);
      transform-origin: right bottom;
      object-fit: cover;
      animation: grow 1.2s ease-in-out forwards;

      &_container {
        text-align: end;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-left: auto;
        display: inline-block;
        overflow: hidden;
        width: 632px;
        height: 464px;

        @media only screen and (max-width: 1680px){
          width: 532px;
          height: 364px;
        }

        @media only screen and (max-width: 1580px){
          width: 432px;
          height: 264px;
        }
      }
    }

    &_description {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;

      max-width: 512px;
      font-size: clamp(22px, 4vw, 36px);
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0.03em;

      @media only screen and (max-width: 1580px){
        max-width: 400px;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
