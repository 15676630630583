@import "../../../styles/variables";

.form__wrap{
    padding: 32px 16px;
    animation: fadeIn 500ms;
    @media (min-width: 840px) {
        padding: 50px 20px;
        max-width: 388px;
        margin: 0 auto;
    } 
}

.input__phone{
    padding-left: 38px;
    font-size: 17px;
}

.form__submit{
    margin: 32px auto 0px;
    min-width: 132px;
    height: 44px;
}

.form__title{
        color: $title;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }

.input{
    font-size: 17px;
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-num
}

.startAdornment{
    font-size: 17px;
    font-weight: 600!important;
    font-variant-numeric: lining-nums proportional-num
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.form__input__loading{
    height: 44px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 22px;
}

.form_error{
    color: $error;
    font-size: 12px;
    padding-top: 12px;
    width: 100%;
    text-align: center;
}