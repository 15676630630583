@import "../../styles/variables";

.footer {
    min-height: 408px;
    background: rgba(255, 255, 255, 0.01);
    height: 100%;
    position: relative;
}

.footer__logo{ 
    width: 50%;
    @media (min-width: 768px ) {
        width: 286px;
    }
    svg{
        max-width: 140px;
        height:  52px;
        color: #fff;}
}

.footer__top {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(235, 233, 240, 0.10);
}

.footer__row.row {
    flex-wrap: wrap;
}

.footer {
    &__help {
        text-align: left;
        color: $title;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        margin-top: 16px;
        @media (min-width: 1200px) {
            margin-top: 0px;
            display: block;
            max-width: 320px;
            text-align: right;
        } 
        @media (min-width: 1440px) {
            max-width: 520px;
            &[data-lang='ru']{
                max-width: 410px;
            }
        } 
    }

    &__help__text {
        color: $title;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 32px;
        font-weight: 500;
        line-height: 100%;
        text-align: center;
        
        @media (min-width: 768px ) {
            margin-bottom: 25px;
            width: 50%;
            margin-bottom: 0px;
            text-align: left;
        }
        @media (min-width: 1090px ) {
            font-size: 46px;
            width: auto;
        }
    }

    &__help__info{
        color: $title;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        @media (min-width: 768px ) {
            display: none;
            text-align: left;
        }
    }
    &__help__actions{
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }
}

.footer__socials{
    gap: 8px;
    justify-content: center;
    .socials__item{
        color: rgba(194, 208, 251, 1);
    }
    @media (min-width: 768px ) {
        gap: 15px;
    }

}

.footer__input{
    max-width: 258px;
}

.footer__send{
    height: 40px;
}

.footer__bottom{
    padding-top: clamp(24px,2.81vw,54px);
}

.footer__bottom__row{
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    align-items: start;
    justify-content: space-between;
    padding-bottom: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        gap: 20px;
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1280px) {
        padding-bottom: 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1520px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    
}
.footer__title{
    color: #C2D0FB;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 16px;
}

.footer__cookie{
    color: $primary_text__light;
    font-size: 14px;
    font-weight: 500;
    padding-top: 25px;
    text-decoration: underline;
    @media (min-width: 768px) {
        padding-top: 15px;
    }
}

.footer__link{
    color:  $primary_text__light;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 700;
}
address.footer__link{
    @media (min-width: 12808px) {
        max-width: 192px;
    }
}
.footer__row__link{
    @media (min-width: 768px) {
        column-gap: 20px;
        justify-content: space-between;
    }
    @media (min-width: 1280px) {
        justify-content: flex-start;
    }
}

.footer__list{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    @media (min-width: 768px) {
        display: block;
    }
    &.contact{
        width: 100%;
        display: block;
        @media (min-width: 1280px) {
            width: auto;
        }
        li{
            padding-bottom: 10px;
        }
    }
    @media (min-width: 768px) {
        li{
            padding-bottom: 10px;
        }
    }
}

.footer__col{
    margin: 0 10px;
}

.footer__col__logo{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-area: 4 / 1;
    @media (min-width: 768px) {
        grid-area: unset;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; 
    }
}

div.footer__socials__mobile{
    justify-content: flex-start;
    width: 50%;
    @media (min-width: 768px ) {
        width: auto;
        margin-top: 23px;
        margin-bottom: 5px;
    }
}

.footer__link__loading{
    width: 100%;
    height: 16px;
    border-radius: 10px;
    &.phone{
        max-width: 135px;
    }
    &.email{
        max-width: 115px;
    }
    &.address{
        height: 32px;
        max-width: 400px;
    }
}

.footer__help__socials{
    font-size: 14px;
    color: rgba(235, 233, 240, 0.60);
    p {
        display: none;
        @media (min-width: 768px) {
            padding-top: 10px;
            display: block;
            text-align: center;
        }
    }
    @media (min-width: 1280px ) {
        margin-left: 74px;
        &[data-lang='ru']{
            margin-left: 0px;
        }
    }
    
    @media (min-width: 1440px ) {
        margin-left: 246px;
        &[data-lang='ru']{
            margin-left: 0px;
        }
    }
}

.footer__col__terabit{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    margin-top: 24px;
    @media (min-width: 768px ) {
        margin-top: 0px;
        grid-area: 3 / 1 / 3 / 3;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1280px ) {
        align-items: flex-start;
        justify-content: space-between;
        display: flex;
        grid-template-columns: unset;
        flex-direction: column;
        grid-area: 2 / 3;
    } 
    @media (min-width: 1520px ) {
        grid-area: unset;
        align-items: flex-end;
        align-self: stretch;
    }
    
}
.footer__licence{
    color: rgba(235, 233, 240, 0.60);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
}