
.container {
    position: relative;
    overflow: hidden;
}
.wrap{
    overflow: hidden;
    position: relative;
}
.swiper {
    width: 100%;
    max-height: 700px;
}

div.swiper__slide {
    background-position: center;
    background-size: cover;
    width: 1024px;
    height: 514px;
    overflow: hidden;
    border-radius: 24px;
    position: relative;

    @media (min-width: 1280px) {
        height: 36.5vw;
    }
    @media (min-width: 1800px) {
        height: 700px
    }
}

.swiper__slide__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.slide__title {
    text-align: center;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
    font-size: clamp(28px, 5vw, 96px);
    font-weight: 300;
    line-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
    transform: translate(-50%, -50%);
    width: 100%;
}
.negativeMargin{
    @media (min-width: 768px) {
        margin: 0 -200px;
    }
    @media (min-width: 2000px) {
        margin: 0 0px;
    }
}
.navigation__btn {
    display: none;

    @media (min-width: 996px) {
        position: absolute;
        z-index: 6;
        top: 50%;
        transform: translateY(-50%);
        background: gba(194, 208, 251, 0.05);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
        backdrop-filter: blur(7.5px);
        cursor: pointer;
        width: 82px;
        height: 80px;
        padding: 24px;
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
        display: block;
    }

    @media (min-width: 996px) {
        width: 140px;
    }
}

.navigation__btn__next {
    right: 0px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 0px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}