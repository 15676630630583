@import "../../../../styles/_variables.scss";

.button {
    padding: 9px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-family: inherit;
    border-radius: 40px;
    font-size: 14px;
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 500;
    @media (min-width: 1280px) {
        padding: 8px 20px;
        font-size: 16px;
    }

    &:disabled,
    &[disabled] {
        pointer-events: none;
        opacity: 0.6;
    }

    &.default {
        background-color: rgba(235, 233, 240, 0.80);
        color: rgba(103, 11, 211, 0.80);
        border: none;
        backdrop-filter: blur(50px);

        @media (hover: hover) {
            &:hover {
                background-color: #fff;
                color: #670BD3;
            }
        }
    }

    &.rainbow-transparent {
        width: 220px;
        height: 80px;
        background-color: transparent;
        backdrop-filter: blur(2px);
        transition: background-color 300ms ease-in-out;
        border-radius: 16px;
        padding: 4px;

        >  svg {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.4;
            transition: opacity 300ms ease-in-out;
        }

        & span {
            transition: color 300ms ease-in-out;
            //color: rgba(255, 255, 255, 0.8);
            color: #A67EC5;
            display: flex;
            border-radius: 8px;
            background: var(--Dark-D40, #22023C66);

            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        @media (hover: hover) {
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);

                svg {
                    opacity: 1;
                }

                span{
                    //color: var(--Button-text-secondary, #670BD3CC);
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    &.rainbow{
        background-image: linear-gradient(90deg, rgba(64, 128, 255, 1) 0%, rgba(244, 96, 230, 1) 49%, rgba(255, 226, 2, 1) 100%);
        padding: 3px;
        display: flex;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
        & span{
            transition: color 300ms ease, background-color 300ms ease;
            background-color: rgba(235, 233, 240, 0.80);
            color: rgba(103, 11, 211, 0.80);
            display: flex;
            padding: 9px 10px;
            border-radius: 40px;
            backdrop-filter: blur(50px);
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        @media (hover: hover) {
            &:hover {
                span{
                    transition: color 300ms ease, background-color 300ms ease;
                    background-color: #fff;
                    color: #670BD3;
                }
            }
        }
    }

    &.outline {
        background-color: transparent;
        color: rgba(235, 233, 240, 0.60);
        border: 2px solid rgba(235, 233, 240, 0.60);
        border-radius: 40px;

        @media (hover: hover) {
            &:hover {

                border-color: #fff;
                color: #fff;
            }
        }
    }

    &.outline-fluid {
        background-color: transparent;
        color: #494187;
        border: 2px solid #494187;
        border-radius: 40px;

        @media (hover: hover) {
            &:hover {
                background-color: #494187;
                border-color: #494187;
                color: #fff;
            }
        }
    }

    &.white {
        background-color: $white;
        color: #494187;
        border: 2px solid $white;

        @media (hover: hover) {
            &:hover {
                background-color: #F1E6D7;
                color: #494187;
                border-color: #F1E6D7;
            }
        }
    }

    &.white-light {
        background-color: rgba(255,255,255,0.6);
        color: $surface;
        border: 2px solid rgba(255,255,255,0.6);

        @media (hover: hover) {
            &:hover {
                background-color: $white;
                color: $surface;
                border-color:  $white;
            }
        }
    }

    &.white-outline {
        background-color: transparent;
        color: rgba(235, 233, 240, 0.60);
        border: 2px solid rgba(235, 233, 240, 0.60);

        @media (hover: hover) {
            &:hover {
                border-color: $white;
                color: $white;
            }
        }
    }

    &.white-sad {
        background-color: $white;
        color: #494187;
        border: 2px solid $white;

        @media (hover: hover) {
            &:hover {
                background-color: rgba(198, 150, 87, 0.2);
                color: #494187;
                border-color: rgba(198, 150, 87, 0.2);
            }
        }
    }

    &.sad {
        background-color: rgba(198, 150, 87, 1);
        color: $white;
        border: 2px solid rgba(198, 150, 87, 1);

        @media (hover: hover) {
            &:hover {
                background-color: rgba(198, 150, 87, 0.7);
                border-color: rgba(198, 150, 87, 0.7);
            }
        }
    }

    &.sad-light {
        background-color: rgba(198, 150, 87, 0.20);
        color: #494187;
        border: 1.5px solid rgba(198, 150, 87, 0.2);

        @media (hover: hover) {
            &:hover {
                color: #494187;
                background-color: rgba(255, 255, 255, 0.20);
                border-color: rgba(198, 150, 87, 0.2);
            }
        }
    }

}

button.button.radius_16{
    border-radius: 16px;
    span{
        border-radius: 16px;
    }
}

button.button.radius_24{
    border-radius: 24px;
    span{
        border-radius: 24px;
    }
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
