@import "src/styles/_variables.scss";

.swiper__misson {
    &__slide {
        //height: clamp(460px, 36.4vw, 700px);
        height: 700px;
        overflow: hidden;
        border-radius: 46px;
        background-color: $surface_light;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &__img {
            width: 100%;
            //height: clamp(300px, 26vw, 500px);
            height: 500px;
            object-fit: cover;
            object-position: center;
            position: relative;
            z-index: 2;
        }

        &__footer {
            // padding-top: clamp(25px, 1.8vw, 35px);
            // padding-bottom: clamp(25px, 1.8vw, 35px);
            // padding-left: clamp(15px, 1.5vw, 30px);
            // padding-right: clamp(15px, 1.5vw, 30px);
            position: relative;
            z-index: 2;
            padding: 30px;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__title {
            font-size: clamp(32px, 2.4vw, 46px);
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 4px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            font-variant-numeric: lining-nums proportional-nums;
            &.right{
                text-align: right;
            }
        }

        &__subtitle {
            //font-size: clamp(16px, 1.1vw, 20px);
            font-size: 20px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            &.right{
                text-align: right;
            }
        }
    }
}