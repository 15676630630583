@import "../../styles/variables";

.header {
    height: 70px;
    display: flex;
    align-items: center;

    &__logo {
        width: clamp(98px, 7vw, 138px);
        height: clamp(20px, 2vw, 38px);
        color: $primary_text;
        cursor: pointer;
    }

    @media (min-width: 1280px) {
        height: 140px;
    }
}

.header__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__actions {
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 40px;
    &__item {
        color: $primary_text;
        cursor: pointer;
        width: 30px;
        height: 30px;
        transition: color 300ms ease, transform 300ms ease;
        position: relative;

        @media (min-width: 1280px) {
            svg {
                transform: scaleY(1);
                transition: color 300ms ease, transform 300ms ease;
            }

            svg:hover {
                transition: color 300ms ease, transform 300ms ease;
                color: $primary_text;
                transform: scaleY(1.2);
            }
        }
    }

    &.active {
        .header__actions__item {
            color: $primary_text;
            transition: color 300ms ease, transform 300ms ease;
        }
    }

    @media (min-width: 1280px) {
        background-color: $surface_light;
        padding: 6px 20px 5px 20px;
    }
}

.header__menu__close {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    color: $primary_text;
}

.header__navigation,
.header__navigation__links {
    display: flex;
    align-items: center;
    border-radius: 40px;
    overflow: hidden;
}

.header__navigation {
    column-gap: 20px;
}

.button.header__signup{
    min-width: 132px;
    min-height: 44px;
}

.header__navigation__links{
    padding: 6px;
    background-color: $surface_light;
}

.button.header__signup {
    border: none;
    padding: 10px 20px;
}

.header__link {
    padding: 6px 18px;
    font-size: 16px;
    color: $primary_text;
    font-weight: 500;

    @media screen and (min-width: 1280px) {

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            border-color: rgba(255, 255, 255, 0.3);
            border-radius: 40px;
        }
    }
}

.header_relative {
    position: relative;
    z-index: 200;
}

.header_absolute {
    position: absolute;
    z-index: 200;
    width: 100%;
    left: 0;
    top: 0;
}

.header_fixed {
    position: fixed;
    z-index: 2001;
    width: 100%;
    left: 0;
    top: 0;
}

.visible {
    transform: translateY(0px);
    transition: transform 0.4s ease-out;
    will-change: transform;
}

.hidden {
    transform: translateY(-200px);
    transition: transform 0.4s ease-out;
    will-change: transform;
}