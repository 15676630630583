.card{
    padding: 16px;
    border-radius: 16px;
    background:  rgba(194, 208, 251, 0.20);
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
}

.preview{
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    box-shadow: 0px 0px 25px 0px rgba(198, 150, 87, 0.20);
    @media (min-width: 768px) {
        width: 60px;
    }
}

.body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 16px;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    @media (min-width: 768px) {
        flex-wrap: nowrap;
        width: calc(100% - 76px);
    }
}

.counter{
    display: flex;
    align-items: center;
}

.body__info{
    display: flex;
    justify-content: space-between;
    width: 95%;
    padding-bottom: 10px;
    padding-right: 20px;
    @media (min-width: 768px) {
        flex-direction: column;
        width: 100%;
        max-width: 240px;
        margin-right: 10px;
        padding-bottom: 0px;
        padding-right: 0px;
    }
    @media (min-width: 1440px) {
        max-width: 320px;
    }
}
.name{
    overflow: hidden;
    color: #EBE9F0;
    font-variant-numeric: lining-nums proportional-nums;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 4px;
    @media (min-width: 768px) {
        padding-bottom: 8px;
    }
}
.color__container{
    display: flex;
    align-items: center;
}
.color__title{
    color:  rgba(235, 233, 240, 0.80);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 400;
    margin-right: 7px;
    &:first-letter{
        text-transform: uppercase;
    }
}
.color__color{
    width: 18px;
    height: 18px;
    border-radius: 20px;
    position: relative;
    span{
        position: absolute;
        width: 19px;
        height: 19px;
        opacity: 0.5;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
    }
}

.price{
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(12px,0.91vw,17px);
    color:  #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
    &.gray{
        color: rgba(235, 233, 240, 0.60);
    }
}

.icon{
    position: absolute;
    right: 0;
    top: -1px;
    cursor: pointer;
    margin-left: 4px;
    @media (min-width: 768px) {
        position: static;
    }
    @media (min-width: 996px) {
        &:hover{
            rect{
                fill-opacity: 0.5;
                transition: fill-opacity 200ms ease;
            }
        }
    }
}

.row{
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: space-between;
    width: 100%;
    @media (min-width: 768px) {
        width: auto;
        justify-content: flex-start;
    }
}

.row__info{
    display: flex;
    gap: 18px;
    align-items: center;
}
.row__info__value{
    font-size: 12px;
    font-weight: 400;
    color:  rgba(235, 233, 240, 0.60);
    padding: 4px 8px;
    border-radius: 4px;
    background: rgba(194, 208, 251, 0.10);
}