@import "src/styles/_variables.scss";

.card {
  display: flex;
  flex-direction: column;
  min-height: 168px;
  border-radius: 16px;
  background: rgba(194, 208, 251, 0.20);
  backdrop-filter: blur(50px);
  padding: 24px 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:hover {
    .tag_position {
      transform: translateX(0%);
      transition: transform 300ms ease;
    }
  }
}

.tag {
  background-color: rgba(51, 179, 172, 0.40);
  padding: 2px 10px;
  min-width: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: clamp(8px, 0.72vw, 14px);
    font-weight: 500;
  }
}

.tag_position {
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  height: auto;
  pointer-events: none;
  transform: translateX(calc(100% - 30px));
  transition: transform 300ms ease;
}

.title {
  color: #EBE9F0;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  pointer-events: none;
  font-variant-numeric: lining-nums proportional-nums;
  margin-bottom: 1px;
  letter-spacing: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.duration {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #EBE9F099;
  text-align: left;

  pointer-events: none;
  font-variant-numeric: lining-nums proportional-nums;
  margin-bottom: auto;
}

.info {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info__users {
  display: flex;
  align-items: center;
}

.info__users__item {
  margin-left: -6px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:first-child {
    margin-left: 0px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    pointer-events: none;
    object-fit: cover;
  }
}

.info__count {
  pointer-events: none;
  color: $text_data_60;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 10px;
  }
}

.info__count__values {
  font-size: 14px;
  font-weight: 400;
  color: $text_data_60;
  font-variant-numeric: lining-nums proportional-nums;
}


.card_empty {
  padding: 24px 16px;
  min-height: 168px;
  border-radius: var(--Radius-Radius-16, 16px);
  background: var(--Surface-surface-tertiary, rgba(194, 208, 251, 0.05));
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.card_empty_title {
  color: rgba(235, 233, 240, 0.40);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 17px;
  font-weight: 500;
}

.card_empty_desc {
  color: rgba(235, 233, 240, 0.20);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-weight: 400;
}

.time_location_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.event_location {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #EBE9F066;
}