.banner{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}
.title{
    font-size: clamp(48px,5vw,96px);
    font-weight: 300;
    line-height: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    padding-bottom: clamp(40px,3.33vw,64px);
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.banner__content{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 58px;
}

.position__top{
    object-position: top;
}

.position__bottom{
    object-position: bottom;
}

.position__center{
    object-position: center;
}

.fill{
    object-fit: fill;
}