@import "src/styles/_variables.scss";

.card {
  border-radius: 16px;
  background: rgba(194, 208, 251, 0.20);
  backdrop-filter: blur(50px);
  padding: 16px;
  transition: opacity 300ms ease-in-out, background 300ms ease-in-out;
  position: relative;
  cursor: pointer;
  height: 100%;

  &.completed {
    opacity: .5;
  }

  &:hover {
    background: rgba(194, 208, 251, 0.25);
  }

  &__title_and_address_wrap {
    display: flex;
    flex-direction: column;
    max-width: 230px;
    min-width: 230px;

    @media (max-width: 830px) {
      max-width: 100%;
    }
  }

  &__title {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 20px;
  }

  &__address {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(235, 233, 240, 0.6);
  }

  &__date_and_duration_wrap {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      flex-direction: row;
      gap: 8px;
    }
  }

  &__date {
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    text-transform: capitalize;
  }

  &__time {
    color: $text_data_40;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
  }

  &__chip {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

    border: rgba(235, 233, 240, 0.05);
    background: rgba(235, 233, 240, 0.05);
    font-variant-numeric: lining-nums proportional-nums;
    border-radius: 32px;
    padding: 4px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(235, 233, 240, 0.80);

    @media (min-width: 830px) {
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__status {
    border-radius: 16px;
    font-variant-numeric: lining-nums proportional-nums;
    line-height: 14px;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 6px 16px;
    text-align: center;
    color: var(--Aqua-A100, rgba(51, 179, 172, 1));
    border: var(--Aqua-A5, rgba(51, 179, 172, 0.05));
    background: var(--Aqua-A10, rgba(51, 179, 172, 0.1));
    max-height: 26px;

    @media (max-width: 830px) {
      padding: 2px 8px;
      max-height: 17px;
      font-size: 12px;
      line-height: 14px;
    }

    &.cancelled {
      color: var(--Rose-R100, rgba(184, 14, 146, 1));
      border: rgba(184, 14, 146, 0.05);
      background: rgba(184, 14, 146, 0.1);
    }

    &.pay_incomplete {
      color: var(--Blue-B100, rgba(64, 128, 255, 1));
      border: var(--Blue-B5, rgba(64, 128, 255, 0.05));
      background: var(--Blue-B10, rgba(64, 128, 255, 0.1));
    }

    &.wait {
      color: #33B3AC;
      border: rgba(51, 179, 172, 0.05);
      background-color: rgba(51, 179, 172, 0.10);
    }

    &.blocked {
      color: #33B3AC;
      border: 1.5px solid rgba(51, 179, 172, 0.05);
      background-color: rgba(51, 179, 172, 0.10);
    }
  }
}

.ru {
  .card__title_and_address_wrap {
    max-width: 220px;
    min-width: 220px;
  }
}


.row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 830px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
}

.col {
  flex: 1;

  &_two {
    min-width: 135px;
  }

  &_last {
    min-width: 100px;
  }

  &_flex_0 {
    display: none;
    flex: 0;
    min-width: 0;
  }
}

.actionButton {
  width: 100px;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.875rem;
  max-height: 26px;

  @media (max-width: 830px) {
    max-height: 17px;
    padding: 4px 8px;
    width: auto;

    font-size: 12px;
    line-height: 14px;
  }

  &.wait {
    color: #B80E92;
    border: 1.5px solid #B80E920D;
    background: #B80E921A;

    &:hover {
      color: #FFFFFF;
      border: 1.5px solid #B80E920D;
      background: #B80E9299;
    }
  }
}

.status_wrap {
  width: auto;
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  justify-content: flex-end;

  @media (max-width: 830px) {
    justify-content: flex-start;
  }

  @media (max-width: 830px) {
    max-height: 17px;

    font-size: 12px;
    line-height: 14px;
  }
}

.mobileButtonsWrapper {
  margin-top: 8px;
  display: flex;
  width: max-content;
  align-items: center;
  gap: 8px;
}

.columnReverse {
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}

.columnsWrapperMobile {
  display: flex;
  width: 110px;
  gap: 16px;
  align-items: center;
}
