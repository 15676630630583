@import "variables";

:root {
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-color: #fff;
    --swiper-pagination-bottom: 28px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}
html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    font-family: 'Inter', sans-serif;
}

footer,
header,
nav,
section,
main {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

address {
    font-style: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

svg path {
    transition: fill 300ms ease;
}

a:hover,
a,
button,
button:hover,
.link,
.link:hover {
    transition: all 300ms ease;
    text-decoration: none;
    // border: none;
}

body {
    color: $primary_text;
    background-color: #151718;
    background:
        radial-gradient(42.35% 82% at 14.35% -6.98%, #22023C 0%, rgba(34, 2, 60, 0) 98.45%),
        radial-gradient(62.14% 32.77% at -2.36% 120.65%, #824BD1 0%, rgba(70, 4, 125, 0) 100%),
        radial-gradient(60.55% 63.7% at 128.68% -10%, #F460E6 0%, rgba(70, 4, 125, 0) 100%),
        linear-gradient(0deg, #000 0%, #000 100%),
        var(--Dark-D100, #22023C);
    // background-repeat: repeat;
    // background-position: center;
    // background-size: contain;
    // background-image: url('../assets/img/body.png');
}

body::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: rgba(194, 208, 251, 0.20);
    /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color:  rgba(194, 208, 251, 0.10);
    /* color of the scroll thumb */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input,
button,
span,
a,
div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type=number] {
    -moz-appearance: textfield;
}


.alexey-make-normal-containers {
    margin-left: 15px;

    @media screen and (min-width: 768px) {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
    }

    @media screen and (min-width: 1280px) {
        margin-left: 50px;
        margin-right: 50px;
        width: calc(100% - 100px);
    }

    @media screen and (min-width: 1922px) {
        max-width: 1690px;
        margin-left: auto;
        margin-right: auto;
    }
}

.container-small {
    width: 100%;
    // margin-left: 15px;
    // margin-right: 15px;
    // width: calc(100% - 30px);
    // @media screen and (min-width: 768px) {
    //     margin-left: 30px;
    //     margin-right: 30px;
    //     width: calc(100% - 60px);
    // }
    @media screen and (min-width: 980px) {
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (min-width: 1280px) {
        width: 100%;
        max-width: 1200px;

    }
}

.container {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);

    @media screen and (min-width: 768px) {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
    }

    @media screen and (min-width: 1280px) {
        margin-left: 50px;
        margin-right: 50px;
        width: calc(100% - 100px);
    }

    @media screen and (min-width: 1922px) {
        max-width: 1690px;
        margin-left: auto;
        margin-right: auto;
    }
}

.container-catalog {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);

    @media screen and (min-width: 768px) {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
    }

    @media screen and (min-width: 1280px) {
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

    }
}

.position_relative {
    position: relative;
    overflow: hidden;
}

.round-paper-sad {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border: 1.5px solid  rgba(235, 233, 240, 0.10);
    background-color: rgba(194, 208, 251, 0.05);
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.row_mobile_column {
        flex-direction: column;

        @media (min-width: 1280px) {
            flex-direction: row;
        }
    }
}



.jcsb {
    justify-content: space-between;
}

.aic {
    align-items: center;
}

.swiper-misson {
    .swiper-pagination-bullet-active {
        background: $milky;
    }
}

.swiper-misson-button-prev.swiper-button-disabled,
.swiper-misson-button-next.swiper-button-disabled {
    opacity: 0;
}

// .gsap-marker-end,
// .gsap-marker-start,
// .gsap-marker-scroller-end,
// .gsap-marker-scroller-start {
//     z-index: 9000 !important;
// }

.swiper-studio {
    .swiper-slide {
        h4 {
            opacity: 0;
            transition: opacity 300ms ease;
        }
    }

    .swiper-slide-active {
        h4 {
            opacity: 1;
            transition: opacity 300ms ease;
        }
    }
}

.master-prev.swiper-button-disabled,
.master-next.swiper-button-disabled{
    opacity: 0;
    transition: opacity 300ms ease;
}


.input__phone {
    padding-left: 48px;
    transition: padding-left 300ms ease;
}

.input__phone__zero {
    padding-left: 20px;
    transition: padding-left 300ms ease;
}

.title-section {
    color: $primary_text__light;
    font-size: clamp(48px, 5vw, 96px);
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 2.88px;
    padding-bottom: 40px;
}


.title-section-small {
    color: $title;
    font-size: clamp(40px, 2.4vw, 46px);
    font-weight: 500;
    line-height: 85%;
    letter-spacing: 1.38px;
}
.padding-bottom_40{
    padding-bottom: 40px;
}
.desc-section {
    color: $primary_text__light;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(16px, 1.1vw, 20px);
    font-weight: 400;
}

.w500{
    font-weight: 500;
}

.react-datepicker-popper {
    z-index: 10;
}

.hr{
    background-color: rgba(198, 150, 87, 0.20);
    height: 1px;
    width: 100%;
}

.skeleton, div.skeleton {
    background: linear-gradient(120deg, rgba(34,2,60,1) 34%, rgba(130,75,209,1) 62%, rgba(130, 75, 209, 0.1) 110%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .skeleton-image {
    background-image: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%) !important;
  }

  @keyframes shine {
    to {
      background-position-x: -200%
    }
  }

.header-offset {
    height: 70px;
    @media (min-width: 1280px) {
        height: 140px;
    }
}
.box20{
    padding-bottom: 20px;
}


.swiper-slice{
    .swiper-slide-active{
        .img-sl{
            transform: scaleX(1) scale(1);

            transition: transform 800ms ease;
            //transition: transform 1000 linear;
        }
        .img-sl-left{
            transform-origin: left;
        }
        .img-sl-right{
            transform-origin: right;
        }
    }

    .swiper-slide-prev{
        .img-sl{
            transform: scaleX(0.4) scale(0.95);
            transform-origin: RIGHT!important;
            transition: transform 800ms ease;
        }
    }
    .swiper-slide-next{
        .img-sl{
            transform: scaleX(0.4) scale(0.95);
            transform-origin: left!important;
            transition: transform 800ms ease;
        }
    }
}

.is-modal-open {
    overflow: hidden;

    &.scroll-lock {
        padding-right: 8px;

        // removes indentation when a modal window is open
        .header_fixed {
            width: calc(100% - 8px);
        }
    }

    // iPhones (scroll down)
    @supports (-webkit-touch-callout: none) {
        position: fixed;
        width: 100%;
        height: 100%;
    }
}

// scroll for modals
.modal-scroll {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(60, 61, 69, 0.2);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(163, 173, 188, 0.1);
    }
}