@import "../../../styles/variables";

.content {
  max-width: 400px;
  padding: 40px 32px;
  margin-left: 12px;
  margin-right: 12px;
  width: 100%;
  background-color: rgba(34, 2, 60, 0.80);
  border-radius: 24px;
  overflow: hidden;
  max-height: 590px;

  overflow-y: auto !important;
}

.readyContent {
  max-width: 400px;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.successModalWrapper {
  & button {
    padding: 2px !important;
    border-radius: 16px;

    span {
      border-radius: 16px !important;
    }
  }
}
