@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.products__item__loading{
    height: clamp(289px, 25vw ,480px);
    border-radius: 10px;
}

.products{
    margin-top: 40px;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    @media (min-width: 680px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px; 
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.catalog__nav{
    display: flex;
    justify-content: center;
    padding-top: clamp(40px,3.1vw,60px);
    padding-bottom: clamp(40px,6.45vw,124px);
}

.more{
    min-width: 220px;
    height: 40px;
}