@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.cart__list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    li{
        width: 100%;
    }
}
.title{
    padding-bottom: 10px;
}

.cart__empty{
    margin: 0 auto;
    max-width: 705px;
    padding: 80px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cart__empty__title{
    color:  #EBE9F0;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(28px,1.9vw,36px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
}
.cart__empty__desc{
    color:  rgba(235, 233, 240, 0.80);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(12px,1vw,20px);
    margin-bottom: 40px;
    margin-top: 16px;
}
.cart__empty__btn{
    height: 60px;
    width: clamp(160px,16vw,320px);
    border-radius: 16px;
    span{
        border-radius: 16px!important;
    }
}