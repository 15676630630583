@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
    overflow: hidden;
}
.text-info-title{
    margin-top: clamp(20px,2.6vw,50px);
}
.text-info-desc{ 
    max-width: 1490px;
    margin: 0 auto;
    margin-top: clamp(20px,2.6vw,50px);
}

.mission{
    min-height: 100vh;
    width: 100%;
    padding-top: clamp(40px,8.02vw,154px);
}

.mission__decor{
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #C2D0FB;
    &.purple{
        color: rgba(73, 4, 131, 0.2);
    }
}

.mission__content{
    position: relative;
    z-index: 2;
    font-variant-numeric: lining-nums proportional-nums;
    margin-bottom: 48px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    @media (min-width: 996px) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    @media (min-width: 1280px) {
        grid-template-columns: 1fr 0.8fr 1fr;
        
    }
}

.mission__content__left{
    max-width: 900px;
    position: relative;
    z-index: 2;
}
.mission__content__right{
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    @media (min-width: 768px) {
        width: 600px;
        height: 600px;
    }
    @media (min-width: 996px) {
        grid-area: 1/2;
        width: clamp(294px, 30.9vw ,594px);
        height: clamp(294px, 30.9vw ,594px);
    }
    
    @media (min-width: 1280px) {
        grid-area: 1/3;
        margin: 0;
    }
}

.mission__content__right__img{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.mission__content__right__info{
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 0;
    text-align: center;
    color: #fff;
}
.mission__content__right__name{
    font-size: clamp(32px,2.4vw,46px);
    font-weight: 500;
    line-height: 85%;
    letter-spacing: 1.38px;
    color: $title;
    @media (min-width: 1280px) {
        font-size: clamp(22px, 2.4vw, 46px);
    }
}
.decor__user__one{
    width: 600px;
    height: 600px;
    left: 0px;
    top: 50%;
    position: absolute;
    z-index: 1;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    transform: scale(3) translateY(-16%) translateX(-18px);
}

.rotate{
    transform: scale(3) translateY(-18%) translateX(5px) rotate(176deg);
}

.mission__content__right__desc{
    font-size: clamp(12px, 1.1vw, 20px);
    font-weight: 400;
    padding: 12px 46px 50px;
    @media (min-width: 768px) {
        padding: 20px 120px 58px;
    }
    @media (min-width: 996px) {
        padding: 20px 46px 50px;
    }
    @media (min-width: 1280px) {
        padding: 20px 100px 56px;
    }
    color: $title;
}
.mission__content__title{
    font-size: clamp(43px,3.3vw,64px);
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 40px;
    color: $title;
}
.mission__content__desc{
    font-size: clamp(16px, 1.1vw, 20px);
    padding-bottom: 40px;
    color: $title;
    font-weight: 500;
}
.mission__content__item{
    margin-bottom: 32px;
    max-width: 564px;
}
.mission__content__item__title{
    margin-bottom: clamp(8px,0.62vw,12px);
    font-size: clamp(17px,1.25vw,24px);
    font-weight: 500;
}
.mission__content__item__desc{
    color: $text_data_60;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
}
.mission__cards{
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
    @media (min-width: 768px) {
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
    }
}
.three{
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }
}
h2.curse__title{
    font-weight: 400;
    letter-spacing: unset;
    font-size: clamp(32px,3.33vw,64px);
}
.curse__desc{
    font-weight: 400;
    max-width: 888px;
}

div.curse__root{
    padding-right: 0px;
}

.work{
    padding-top: clamp(40px,6.6vw,127px);
    padding-bottom: 31px;
}
div.work__slide{
    height: 550px;
}

.work__slider{
    margin-bottom: clamp(20px,4.27vw,82px);
}

.reviews{
    padding: 25px 0px 60px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.reviews__decor{
    position: absolute;
    width: auto;
    height: auto;
    bottom: -214px;
    left: 0;
}

.contact__desc{
    max-width: 1280px;
}

.banner__btn{
    min-width: 132px;
    height: 44px;
}

.variants{
    margin-bottom: 80px;
}

.section__grid__bg{
    display: none;
    @media (min-width: 996px) {
        display: block;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: auto;
        height: auto;
        right: 0;
        z-index: 0;
    }
}


.section__grid{
    position: relative;
    overflow: hidden;
    margin-top: 64px;
    @media (min-width: 1100px) {
        margin-bottom: -168px;
        margin-top: 84px;
    }
}

.zIndex{
    z-index: 4;
    padding-bottom: 20px;
    @media (min-width: 1100px) {
        padding-bottom: 0px;
    }
}

.row{
    display: flex;
}

.end{
    justify-content: center;
    @media (min-width: 768px) {
        justify-content: flex-end;
    }
    
}

.center{
    justify-content: center;
}
.gap{
    margin-left: 10px;
    margin-right: 10px;
}

.topY{
   
    @media (min-width: 1100px) {
        transform: translateY(-168px);
    }
}

.row__group__start{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-around;
        gap: 0px;
    }
    @media (min-width: 1100px) {
        margin-bottom: -258px;
        display: block;
    }
}
.row__group__end{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    @media (min-width: 768px) {
        gap: 0px;
        justify-content: space-around;
        flex-direction: row;
    }
    @media (min-width: 1100px) {
        transform: translateY(-76px);
        display: block;
    }
}

.row__group__center{
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (min-width: 1100px) {
        justify-content: flex-start;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.section_title{
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-weight: 400;
    font-size: clamp(40px,3.33vw,64px);
    line-height: 100%;
    white-space: pre-line;
    color: $title;
}
.section_title_position{
    margin-bottom: 40px;
    @media (min-width: 1100px) {
        position: absolute;
        left: 0;
        top: 0;
    }
}


.text-info-title{
    margin-top: clamp(20px,2.6vw,50px);
}
.text-info-desc{ 
    max-width: 920px;
    margin: 0 auto;
    margin-top: 32px;
}
.text-reviews-root{
    padding: 72px 15px 56px;
}