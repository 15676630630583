.header {
    display: flex;
    padding-right: 15px;
    flex-direction: column;
    margin-bottom: clamp(25px, 4.1vw, 80px);
    row-gap: 20px;

    h2 {
        padding-bottom: 0px;
    }

    @media (min-width: 996px) {
        row-gap: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width: 1280px) {
        padding-right: 50px;
    }
}

.desc {
    max-width: 970px;

    @media (min-width: 996px) {
        padding-left: 80px;
        &[data-lang='ru']{
            max-width: 660px;
        } 
    }
    @media (min-width: 1280px) {
        padding-left: 10px;
    }
}

h2.small{
    font-size: clamp(28px, 3.5vw, 68px);
}

.padding{
    margin-bottom: clamp(25px, 3.1vw, 60px);
}