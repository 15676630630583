//@import "src/styles/_variables.scss";
// colors
$sad: #C69657;
$zIndexHeader: 2001;
$background: #FCFAF7;
$primary_text: rgb(235, 233, 240);
$primary_text__light: rgba(235, 233, 240, 0.80);
$white: white;
$maxWidthContainer: 1690; // max size container
$maxWidth: 1920; // max width  window
$black: #000;
$milky: #EDDBC7;
$purple: #494187;
$sad_light: rgba(198, 150, 87, 0.2);
$purple_light: rgba(73, 65, 135, 0.50);
$error: rgba(240, 85, 85, 0.8);
$surface_light: rgba(103, 11, 211, 0.6);
$surface: rgba(103, 11, 211, 1);
$dark60: rgba(34, 2, 60, 0.6);
$dark: rgba(34, 2, 60);
$modal_bg: rgba(34, 2, 60, 0.80);
$paper: rgba(34, 2, 60, 0.80);
$link_light: rgba(64, 128, 255, 0.80);
$link: rgba(64, 128, 255, 1);
$border: rgba(235, 233, 240, 0.60);
$title: #EBE9F0;
$text_data_60: rgba(235, 233, 240, 0.60);
$text_data_40: rgba(235, 233, 240, 0.40);

$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 996px;
$xl: 1280px;
$xxl: 1440px;
$full: 1920px;
$big: 2499px;

@mixin adaptive-var($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;

    @if $type ==1 {
        #{$property}: $startSize + px;

        @media (max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidthContainer - 320}));
        }
    }

    @else if $type ==2 {
        #{$property}: $startSize + px;
 
        @media (min-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidth - 320}));
        }
    }

    @else {
        #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px)/#{$maxWidth - 320}));
    }
}

@function fnclamp($min, $max) {
    $var: calc(calc($max / 1920) * 100);
    @return clamp(#{$min}px, #{$var}vw ,#{$max}px);
}


