@import "src/styles/_variables.scss";
.section {
    position: relative;
}

.section__padding{
    padding-top: clamp(40px, 7.8vw, 83px);
    padding-bottom: 30px;
}


.title {
    text-align: center;
    color: $title;
    font-size: clamp(48px, 3.33vw, 64px);
    font-weight: 400;
    line-height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: clamp(40px,2.5vw,48px);
}