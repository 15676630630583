@import "src/styles/_variables.scss";

.form__wrapper{
    border-radius: 24px;
    background:  rgba(194, 208, 251, 0.20);
    margin-bottom: 20px;
}

.row{
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 15px;
    @media (min-width: 768px) {
        padding: 28px 24px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
.row__title{
    color:  $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
}
.hr{
    width: 100%;
    height: 1px;
    background-color: rgba(194, 208, 251, 0.10);
}

.col{
    width: 100%;
    @media (min-width: 768px) {
        width: 50%;
    }
}

.row_fieds{
    padding-top: 0px;
}