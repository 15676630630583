@import "../../../styles/variables";

.form__wrap{
    padding: 32px 16px;
    animation: fadeIn 500ms;
    @media (min-width: 840px) {
        padding: 50px 20px;
        max-width: 388px;
        margin: 0 auto;
    } 
}

.input__phone{
    padding-left: 38px;
    font-size: 17px;
}

.form__submit{
    margin: 32px auto 0px;
    min-width: 132px;
    height: 44px;
}

.input{
    font-size: 17px;
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-num
}

.startAdornment{
    font-size: 17px;
    font-weight: 600!important;
    font-variant-numeric: lining-nums proportional-num
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  .form__title{
    color: $title;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.form__eye {
    border: none;
    background: none;
    cursor: pointer;
    color: rgba(235, 233, 240, 0.8);
    margin-top: 4px;
    margin-right: -9px;
}

.message{
    margin-top: 10px;
}

.error{
    color: $error;
}

.success{
    color: $title;
}