@import "../../../../styles/variables";

.eventInfoWrapper {
  display: flex;
  gap: 16px;
}

.eventStepperWrapper {
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.event__info__price {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.price {
  color: $title;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  font-variant-numeric: lining-nums proportional-nums;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.count {
  display: flex;
  align-items: center;
  color: rgba(235, 233, 240, 0.40);
  font-size: 14px;
  font-weight: 500;
  font-variant-numeric: lining-nums proportional-nums;

  & img {
    margin-right: 10px;
  }
}
