
.schedule {
    margin-top: 20px;

    @media (min-width: 768px) {
        margin-top: 60px;
    }
}

.box40 {
    padding: 20px;
}

.banners__loading{
    min-height: 373px;
    border-radius: 10px;

    @media (min-width: 1280px) {
        min-height: 349px;
    }
}