.socials__item {
    color: #fff;

    svg {
        width: 26px;
        height: 26px;
    }
}

a.socials__item {
    &.big{
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
    &_default {
        color: #C2D0FB;
        background-color: rgba(194, 208, 251, 0.05)
    }
    &:hover{
        background-color: rgba(194, 208, 251, 0.2);
    }
    &_user {
        color: #494187;
        background-color: rgba(198, 150, 87, 0.20);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
        backdrop-filter: blur(7.5px);
    }
}

.socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
}