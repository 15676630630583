@import "src/styles/_variables.scss";

.card{
    border-radius: 16px;
    background:  rgba(194, 208, 251, 0.20);
    backdrop-filter: blur(50px);
    padding: 12px 16px;
    position: relative;
}

.title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 7px;
}

.row{
    color: #494187;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 18px 1fr;
    grid-template-areas: "name name name" "position payment order" "price price count";
    gap: 16px;
    @media (min-width: 830px) {
        grid-template-columns: 130px 59px minmax(87px,150px) minmax(87px,150px) minmax(87px,150px) auto 20px;
        grid-template-rows: auto;
        grid-template-areas: "name count position payment order price icon";
        gap: 0px;
    }
}
.name {
    grid-area: name;
}
.count{
    text-align: center;
    align-items: center;
    display: grid;
    color: $text_data_60;
    font-variant-numeric: lining-nums proportional-nums;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    grid-area: count;
    &:hover{
        .cout__list{
            opacity: 1;
            z-index: 3;
            visibility: visible;
            transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
        }
        .count__value{
            svg{
                transform: rotate(-90deg);
                transition: transform ease 300ms;
            }
        }
    }
    &__value{
        padding: 3px 2px 3px 5px;
        border-radius: 10px;
        background: var(--Surface-surface-tertiary, rgba(194, 208, 251, 0.05));
        font-size: clamp(12px,0.72vw,14px);
        font-weight: 500;
        width: fit-content;
        min-width: 32px;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        svg{
            transition: transform ease 300ms;
            transform: rotate(0deg);
        }
    }

    &__title{
        font-size: clamp(12px,0.72vw,14px);
        font-weight: 500;
        color: $text_data_40;
    }

}
.position{
    text-align: center;
    grid-area: position;
    @media (min-width: 830px) {
        align-items: center;
        display: grid;
        justify-content: center;
    }
    &__chip{
        color: rgba(235, 233, 240, 0.80);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: clamp(12px,0.72vw,14px);
        font-weight: 400;
        border-radius: 32px;
        background-color: rgba(235, 233, 240, 0.05);
        padding: 1px 4px;
        border: 1.5px solid rgba(235, 233, 240, 0.05);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &[data-lang="ru"]{
            font-size: clamp(8px,0.62vw,12px);
        }
        @media (min-width: 830px) {
            padding: 4px 12px;
        }
    }
}

.payment{
    text-align: center;
    grid-area: payment;
    @media (min-width: 830px) {
        align-items: center;
        display: grid;
        justify-content: center;
    }
    &__chip{
        color: #33B3AC;
        border: 1.5px solid rgba(51, 179, 172, 0.05);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: clamp(12px,0.72vw,14px);
        font-weight: 400;
        border-radius: 20px;
        background-color: rgba(198, 150, 87, 0.20);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 1px 4px;
        &[data-lang="ru"]{
            font-size: clamp(8px,0.62vw,12px);
        }
        &.NOT_PAYED{
            color: #4080FF;
            border: 1.5px solid rgba(64, 128, 255, 0.05);
            background: rgba(64, 128, 255, 0.10);
        }
        @media (min-width: 830px) {
            padding: 4px 12px;
        }
    }

}
.order{
    text-align: center;
    grid-area: order;
    
    @media (min-width: 830px) {
        display: grid;
        align-items: center;
        
        justify-content: center;
    }
    &__chip{
        color: #fff;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: clamp(12px,0.72vw,14px);
        font-weight: 400;
        border-radius: 20px;
        border: 1.5px solid rgba(235, 233, 240, 0.05);
        background: rgba(64, 128, 255, 0.60);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 1px 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &[data-lang="ru"]{
            font-size: clamp(8px,0.62vw,12px);
        }
    }
    &.DISPATCHING{
        border: 1.5px solid rgba(235, 233, 240, 0.05);
        background: rgba(184, 14, 146, 0.60);
    }
    &.DELIVERED{
        border: 1.5px solid rgba(235, 233, 240, 0.05);
        background: rgba(51, 179, 172, 0.60);
    }
    @media (min-width: 830px) {
        padding: 4px 12px;
    }

}
.icon{
    align-items: center;
    justify-content: flex-end;
    display: grid;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 24;
    cursor: pointer;
    grid-area: icon;
    @media (min-width: 830px) {
        position: static;
        top: unset;
        right: unset;
        justify-content: center; 
    }
}



.date{
    color: $text_data_40;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.price{
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-weight: 500; 
    display: flex;
    align-items: center;
    padding-right: 16px;
    grid-area: price;
    @media (min-width: 830px) {
        justify-content: flex-end;  
    }
}

.cout__list{
    position: absolute;
    border-radius: 16px;
    background:  rgb(66 72 91 / 90%);
    backdrop-filter: blur(5px);
    padding: 16px;
    top: 100%;
    right: -24px;
    width: 360px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
    @media (min-width: 830px) {
        left: 80px;
        right: unset;
    }
    &__item{
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #EBE9F0;
        img{
            width: 32px;
            height: 20px;
            border-radius: 4px;
        }
        p{
            width: 150px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .color{
            width: 16px;
            height: 16px;
            border-radius: 16px;
        }
        .price{
            white-space: nowrap;
        }
    }
}