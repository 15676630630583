@import "../../../styles/variables";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radio {
    display: block;
}

.radio__title {
    color: $title;
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 16px;
}

.radio__list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.radio__item {
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid $sad_light;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-color 300ms ease, background-color 300ms ease;
    background-color: transparent;
    text-transform: uppercase;

    &__active {
        border-color: rgba(244, 96, 230, 0.80);
        border: 2px solid rgba(244, 96, 230, 0.80);
        transition: border-color 300ms ease, background-color 300ms ease;
        background-color: rgba(32, 33, 36, 0.03);
    }

    &:hover {
        background-color: rgba(32, 33, 36, 0.03);
        border-color: rgba(244, 96, 230, 0.80);
        border: 2px solid rgba(244, 96, 230, 0.80);
        transition: border-color 300ms ease, background-color 300ms ease;
    }
}

.item__color {
    width: 60px;
    height: 60px;
    display: block;
}

.item__text {
    line-height: 110%;
    color: rgba(235, 233, 240, 0.60);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 52px;
    padding: 8px 16px;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease;
    border-color: transparent;
    backdrop-filter: blur(50px);
    background-color: rgba(194, 208, 251, 0.10);
    &:hover {
        background-color: rgba(194, 208, 251, 0.30);
        color: $white;
        transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease;
        border-color: rgba(194, 208, 251, 0.10);
    }
    &.radio__item__active{
        background-color: rgba(194, 208, 251, 0.30);
        color: $white;
        transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease;
        border-color: rgba(194, 208, 251, 0.10);
    }
}
.item__text__skeleton{
    border: 2px solid $sad_light;
}
.item__img__skeleton{
    width: 60px;
    height: 60px;
}

.radio__title__skeleton{
    margin-bottom: 15px;
    height: 14px;
    border-radius: 10px;
    width: 120px;
}