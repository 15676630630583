@import "src/styles/_variables.scss";

.section {
    padding-top: clamp(65px, 5.9vw, 114px);
    padding-bottom: clamp(65px, 3.7vw, 71px);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

}
.section_animation{
    animation: bg-anim 30000ms ease infinite;
    -webkit-animation: bg-anim 30000ms ease infinite;
    background-repeat: repeat;
}


@-webkit-keyframes bg-anim {
    0% {
        background-position-y: 0
    }
    to {
        background-position-y: 100vh
    }
}

@keyframes bg-anim {
    0% {
        background-position-y: 0
    }
    to {
        background-position-y: 100vh
    }
}


.content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: $title;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: fnclamp(38,64);
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 2.88px;
    padding-bottom: 25px;
    width: 100%;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
}

.desc {
    font-size: fnclamp(14,20);
    font-weight: 500;
    color: $primary_text__light;
    padding-bottom: 30px;
    width: 100%;
    text-align: center;
    max-width: 900px;
}

button.btn{
    width: 220px;
    height: 60px;
    border-radius: 16px;
    & > span{
      border-radius: 16px!important;  
    }
}