@import "../../styles/variables";

.page {
  min-height: calc(100vh - 408px);
}

.title {
  font-size: clamp(28px, 2.4vw, 46px);
}

.privacy__container {
  margin: clamp(20px, 2.08vw, 40px) auto;
  max-width: 750px;
  padding-bottom: clamp(20px, 3.2vw, 63px);
}

.privacy__content {
  color: rgba(235, 233, 240, 0.80);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: clamp(14px, 1.04vw, 20px);
  font-weight: 400;
  padding-top: 40px;

  h2 {
    font-size: clamp(20px, 2.4vw, 38px);
    font-weight: 400;
    margin-bottom: 32px;
    text-align: start !important;
  }

  a {
    white-space: nowrap;
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $primary_text__light;
    }
  }

  h2 {
    text-align: center;
    margin: 10px 0 24px;
  }

  p, ul {
    margin: 1em 0;
  }

  p, ul, li {
    font-size: clamp(14px, 1.04vw, 20px);
    line-height: 1.2em;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}