.card{
    position: relative;
    width: 100%;
    height: clamp(128px,30vw,576px);
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 16px;
    @media (min-width: 768px) {
        width: clamp(320px,30vw,576px);
        height: clamp(320px,30vw,576px);
        border-radius: 24px;
    }
    @media (min-width: 1925px) {
        width: clamp(320px,30.88vw,552px);
        height: clamp(320px,30.88vw,552px);
    }
    @media (hover: hover) {
        &:hover{
            .card__hover{
                transform: translate(-50%, -50%) scale(1.6);
                transition: transform 600ms ease;
            }
            .card__description{
                opacity: 1;
                transition: opacity 1000ms ease;
            }
        }
    }  
}
.card__img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.card__description{
    z-index: 4;
    position: absolute;
    color: #fff;
    font-size: clamp(14px,0.9vw,18px);
    font-weight: 400;
    line-height: 140%;
    padding: clamp(15px,2.6vw,50px) clamp(10px,2.08vw,40px);
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 300ms ease;
}
.card__title{
    z-index: 4;
    position: absolute;
    color: #fff;
    padding: clamp(15px,1.25vw,34px) clamp(10px,1.6vw,32px);
    left: 0;
    bottom: 0;
    font-size: clamp(22px,1.8vw,36px);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 1.08px;
}
.card__hover{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 3;
    border-radius: 100%;
    transition: transform 600ms ease;
}