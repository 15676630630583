@import "../../../styles/_variables.scss";

.section {
    min-height: 100dvh;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover ! important;
    background-position: center ! important;
}

.section__title{
	text-align: center;
	font-style: normal;
	font-weight: 300;
	line-height: 100%;
    color: $title;
	padding-left: 20px;
	padding-right: 20px;
    position: absolute;
    z-index: 4;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    font-size: 42px;
    top: 30%;
    @media (min-width: 768px) {
        top: 50%;
        font-size: clamp(4.2rem, 5vw, 96px);
    }
}

.bg {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1;
}

.content {
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.slider {
    width: 100%;
    height: 100dvh;
}

.slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    position: relative;

    &:hover {
        .hover {
            transition: all 200ms linear;
            z-index: 3;
            opacity: 1;
            visibility: visible;
        }
    }
}


.hover {
    position: absolute;
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 200ms linear;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    left: 0;
    bottom: 0;
    justify-content: flex-end;
    backdrop-filter: blur(15px);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    background: rgba(103, 11, 211, 0.80);
    
}
.content__hover{
    padding: 40px 28px;
}
.title {
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    text-transform: lowercase;
    &__visible{
        min-height: 240px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 40px 24px;
    }
}

.desc {
    padding-top: 16px;
    padding-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
    text-align: center;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
    @media (min-width: 1440px) {
        flex-direction: row;
    }
}

.btn {
    min-width: 132px;
    min-height: 44px;
    @media (min-width: 1280px) {
        padding: 8px 15px;
        font-size: 14px;
      
    }

}

.navigation__btn {
    position: absolute;
    z-index: 6;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(103, 11, 211, 0.6);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
    backdrop-filter: blur(7.5px);
    cursor: pointer;
    width: 82px;
    height: 80px;
    padding: 24px;
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;

    @media (min-width: 996px) {
        width: 140px;
    }

    @media (min-width: 1280px) {
        display: none;
    }

}

.navigation__btn__next {
    right: 0px;
    border-radius: 80px 0px 0px 80px;
}

.navigation__btn__prev {
    left: 0px;
    border-radius: 0px 80px 80px 0px;

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}

.hide {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}

.slide__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.grid{
    display: grid;
    gap: 8px;
    grid-template-rows: 1fr 1fr 1fr;

}
.grid__row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    height: clamp(138px, 15.41vw, 296px);
    gap: 8px;
    &:nth-child(2){
        grid-template-columns: 1fr;
    }
}
.section-mobile{
    position: relative;
    padding-top: 72px;
    padding-bottom: 72px;

}

.section__title__mobile{
    color:  #EBE9F0;
    font-size: 48px;
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 40px; 
}
