.container {
    @media (min-width: 768px) {
        border-radius: 10px;
        position: relative;
    }
}

.swiper {
    @media (min-width: 768px) {
        border-radius: 10px;
    }
}

.slide {
    position: relative;
    overflow: hidden;
    min-height: 373px;
    border-radius: 10px;

    @media (min-width: 1280px) {
        min-height: 240px;
    }
}

.bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.info {
    position: absolute;
    width: 100%;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 15px;
    max-width: 680px;

    @media (min-width: 768px) {
        padding: 25px 50px;
    }

    @media (min-width: 1280px) {
        width: 50%;
    }
}

.subtitle,
.time,
.address {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clac(14px, 0.83vw, 16px);
    font-weight: 500;
}

.time,
.address {
    font-size: clamp(14px, 1.25vw, 24px);
}

.title {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(24px, 2.08vw, 40px);
    font-weight: 600;
    line-height: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.desc {
    font-size: clac(14px, 0.83vw, 16px);
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.header,
.header__info {
    display: flex;
    align-items: center;
}

.header__info {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.header {
    justify-content: space-between;
}

.sp {
    display: none;

    @media (min-width: 768px) {
        display: block;
        margin-left: 18px;
        margin-right: 18px;
    }
}

.btn {
    width: 135px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;

    span {
        width: 10px;
        height: 10px;
        background-color: #EDDBC7;
        cursor: pointer;
    }
}

.navigation__btn {
    display: none;

    @media (min-width: 768px) {
        display: block;
        position: absolute;
        z-index: 6;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(194, 208, 251, 0.05);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset;
        backdrop-filter: blur(50px);
        cursor: pointer;
        width: 60px;
        height: 60px;
        padding: 10px;
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease, z-index 300ms ease, visibility 300ms ease;
    }

    @media (min-width: 1280px) {
        width: 90px;
        height: 90px;
        padding: 24px;
        &:hover{
            opacity: 0.8;
        }
    }

}

.navigation__btn__next {
    right: -30px;
    border-radius: 80px 0px 0px 80px;

    @media (min-width: 1280px) {
        right: -50px;
    }

    @media (min-width: 1921px) {
        border-radius: 100%;
    }
}

.navigation__btn__prev {
    left: -30px;
    border-radius: 0px 80px 80px 0px;

    @media (min-width: 1280px) {
        left: -50px;
    }

    @media (min-width: 1921px) {
        border-radius: 100%;
    }

    .navigation__btn__icon {
        transform: rotate(180deg);
    }
}

.navigation__btn__icon {
    width: 100%;
    height: 100%;
}

.navigation__btn__hide.navigation__btn{
    display: none;
    @media (min-width: 768px) {
        display: none;
    }
}