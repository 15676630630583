@import "../../styles/variables";

.page{
    min-height: calc(100vh - 408px);
}

.buyers__container{
    margin: clamp(20px,2.08vw,40px) auto;
    max-width: 750px;
    padding-bottom: clamp(20px,3.2vw,63px);
}
.buyers__content{
    color: rgba(235, 233, 240, 0.80);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(14px,1.04vw,20px);
    font-weight: 400;
    padding-top: 40px;
    p{
        padding-bottom: 20px;   
    }
}