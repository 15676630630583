.specifications{
    width: 100%;
}

.specifications__item__type{
    display: flex;
    white-space: nowrap;
    color:  rgba(235, 233, 240, 0.80);
    font-size: 14px;
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
}
.specifications__item__sp{
    width: 100%;
    border-bottom: 2px dotted rgba(235, 233, 240, 0.80);
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    min-width: 10px;
}
.specifications__item__name{
    display: flex;
    white-space: nowrap;
    color:  #fff;
    font-size: 14px;
    font-weight: 500;
    font-variant-numeric: lining-nums proportional-nums;
}

.specifications__item{
    display: flex;
    margin-bottom: 20px;
}