:root {
	--index: calc(1vw + 1vh);
	--transition: 1.5s cubic-bezier(.05, .5, 0, 1);
}


.leaf-scene {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	will-change: auto;
}

.falling-leaves {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.leaf-scene div {
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 2px;
	border-radius: 100%;
	background-color: #FDA063;
	/* background: url(./leaf.svg) no-repeat; */
	background-size: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-backface-visibility: visible;
	backface-visibility: visible;
	will-change: auto;
}


.layers {
	perspective: 1000px;
	overflow: hidden;
}

.layers__container {
	height: 100vh;
	min-height: 500px;
	transform-style: preserve-3d;
	transform: rotateX(var(--move-y)) rotateY(var(--move-x));
	will-change: transform;
	transition: transform var(--transition);
	will-change: auto;
}

.layers__item {
	position: absolute;
	inset: -5vw;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.layers__button {
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translate(-50%, 0);
	opacity: 1;

	& span {
		font-size: 30px;
	}

	&--ru {
		width: 214px;

		span {
			font-size: 26px;
		}
	}

	@media (min-width: 1280px) {
		opacity: 0;
	}
}

.layers__svg {
	&.left{
		left: 0px;
		width: clamp(100px,19.4vw,373px);
		height: clamp(120px,21.5vw,414px);
		transform: translateY(-50%) translateX(0%);
		@media (min-width: 768px) {
			transform: translateY(-50%) translateX(80%);
		}
		&.active{
			@media (min-width: 768px) {
				transform: translateY(-50%) translateX(0%);
			}
		}

	}
	&.right{
		right: 0px;
		width: clamp(116px,24.7vw,475px);
		height: clamp(114px,21.5vw,414px);
		transform: translateY(-50%) translateX(0%);
		@media (min-width: 768px) {
			transform: translateY(-50%) translateX(-50%);
		}
		&.active{
			@media (min-width: 768px) {
				transform: translateY(-50%) translateX(0%);
			}
		}
	}
	color: #fff;
	position: absolute;
	top: 50%;
	transform: translateY(-200%);
	transition: transform 1800ms ease;
	// @media (min-width: 768px) {
	// 	transform: translateY(-50%);
	// }
}

.layers__text{
	position: absolute;
	bottom: 0px;
	transform: translateY(-20px) translateX(50%);
	left: 0px;
	font-size: clamp(32px,3.33vw,64px);
	font-weight: 400;
	line-height: 100%;
	transition: transform 1800ms ease;
	@media (min-height: 765px) {
		transform: translateY(-50px) translateX(50%);
	}
	@media (min-height: 1000px) {
		transform: translateY(-100px) translateX(50%);
	}
	&.active{
		transform: translateY(-20px) translateX(0%);
		@media (min-height: 765px) {
			transform: translateY(-50px) translateX(0%);
		}
		@media (min-height: 1000px) {
			transform: translateY(-100px) translateX(0%);
		}
	}
}

.layer-1 {
	transition: transform 1400ms ease-in;
	will-change: transform;
	transform: translateZ(-55px) scale(1) translateY(-220px);
	&.active{
		transform: translateZ(-55px) scale(1.1) translateY(0px);
	}
}

.layer-2 {
	transform: translateZ(80px) scale(1.4);
	will-change: transform;
	transition: transform 1000ms ease;
	&.active{
		transform: translateZ(80px) scale(0.9);
	}
}

.layer-3 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	width: 100%;
	height: 100%;
	&>.container{
		height: 100%;
	}
}


.container-animation {
	position: relative;
	overflow: hidden;
}

.layer-5 {
	transform: translateZ(300px) scale(1.3);
	transition: transform 1700ms ease;
	will-change: transform;
	&.active{
		transform: translateZ(300px) scale(0.7);
	}
}


.hero-content h1 {
	font-size: clamp(4.2rem, 6.6vw, 7.68rem);
	text-align: center;
	font-style: normal;
	font-weight: 300;
	line-height: 100%;
	text-transform: uppercase;
	color: #fff;
	padding-left: 20px;
	padding-right: 20px;
}




.layer-4,
.layer-5,
.layer-6 {
	pointer-events: none;
}
