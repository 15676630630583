.hr {
  position: relative;
  height: 1px;
  margin: 20px 0 10px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -24px;
    right: -24px;
    height: 1px;
    background-color: rgba(235, 233, 240, 0.10);
  }
}