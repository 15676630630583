.title{
    color:  rgba(235, 233, 240, 0.40);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
    font-weight: 400;
}
.link{
    padding-top: 8px;
}
.logo{
    max-width: 120px;
    display: block;
}