.card{
    border-radius: 24px;
    position: relative;
    height: clamp(128px,9.3vw,180px);
    padding: clamp(16px,1.25vw,24px);
    overflow: hidden;
    
}
.bg{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    border-radius: 10px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.icon{
    position: absolute;
    z-index: 2;
    right: 30px;
    top: 30px;
    width: 60px;
    height: 60px;
}
.text{
    position: absolute;
    z-index: 3;
    color:  #494187;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: clamp(16px,1.25vw,24px);
    font-weight: 500;
    line-height: 100%; 
    letter-spacing: 0.72px;
    white-space: pre-line;
    padding-right: 82px;
    left: 30px;
    bottom: 30px;
    &.white{
        color: #fff;
    }
    &[data-lang="ru"]{
        font-size: clamp(14px,1.04vw,20px);
        padding-right: 100px;
    }
}

.purple{
    background-color: transparent;
}

.decor{
    z-index: 1;
    right: 0;
    top: 0;
    position: absolute;
    transform: translate(30%, 16%) rotate(155deg) scale(1.3);
    opacity: 0.6;
}