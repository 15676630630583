@import "../../../styles/variables";

.header{
    position: relative;
    padding-bottom: 20px;
}
.title{
    color: #EBE9F0;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.72px;
    text-align: center;
}

.body{
    padding-bottom: 8px;
    padding-top: 8px;
}

.actions{
    position: relative;
    padding-top: 28px;
}
.hr{
    position: absolute;
    left: -17px;
    width: calc(100% + 32px);
    background-color: rgba(235, 233, 240, 0.20);
}
.hr_bottom{
    bottom: 0;
}
.hr_top{
    top: 0;
}
.pd_20{
    padding-top: 28px;
}
.btn{
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    height: 44px;
}

.mg15{
    margin-bottom: 15px;
}

.policy{
    color: rgba(235, 233, 240, 0.40);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    a{
        text-decoration: underline;
        color: rgba(235, 233, 240, 0.40);
    }
}

.list_item{
    div[data-style="address"]{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
}