@import "src/styles/_variables.scss";

.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &__span {
        display: flex;
        align-items: center;
    }
}

.breadcrumbs__item {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &_default {
        color: $primary_text__light;
    }

}

.breadcrumbs__item__link {
    cursor: pointer;
    transition: color 300ms ease;

    &_default {
        color: $text_data_40;

        &:hover {
            color: $text_data_60;
            transition: color 300ms ease;
        }
    }

}

.breadcrumbs__sp {
    width: 5px;
    height: 5px;
    border-radius: 100px;
    margin: 0 5px;

    &_default {
        background-color: $text_data_40;
    }

}

.breadcrumbs__item__skeleton {
    height: 10px;
    width: 40px;
    border-radius: 10px;
    &:nth-child(2){
        width: 80px;
    }

    &:nth-child(3){
        width: 100px;
    }
}