a.locations_wrapper {
  text-decoration: underline;
  color: var(--Text-text-tertiary, #EBE9F099);
  transition: color 300ms ease;

  &:hover {
    color: rgba(235, 233, 240, 0.8);

    > div {
      color: rgba(235, 233, 240, 0.8);
    }
  }
}

.locations_wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.event_location {
  color: var(--Text-text-tertiary, #EBE9F099);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: clamp(14px, 0.93vw, 16px);
  font-weight: 500;
}

.event_location_address {
  color: var(--Text-text-tertiary, #EBE9F099);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: clamp(14px, 0.93vw, 16px);
  font-weight: 500;
}