@import "../../styles/variables";

.switcherLang {
    color: $primary_text;
    position: relative;

    @media screen and (min-width: 996px) {
        &:hover {
            .switcherLangBtnCustom {
                color: #999999;
                transition: color 300ms ease;
            }

            .switcherLangList {
                opacity: 1;
                z-index: 1;
                visibility: visible;
                transition: all 400ms ease;
            }

            .switcherLangIcon {
                transform: rotate(90deg);
                transition: transform 400ms ease;
            }
        }
    }
}

.switcherLangBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    cursor: pointer;
    position: relative;
    transition: color 300ms ease;
    padding-top: 2px;
    padding-bottom: 2px;
    min-width: 48px;
    border-radius: 5px;
}

.switcherLangBtnCustom {
    color: $primary_text;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 62px;
}


.switcherLangTitle {
    color: $primary_text;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
}

.switcherLangList {
    padding: 10px 15px;
    min-width: 82px;
    opacity: 0;
    transition: all 400ms ease;
    visibility: hidden;
    border-radius: 10px;
    background: $dark;

    &Item {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-child {
            padding-bottom: 0;
        }
    }

    &Open {
        opacity: 1;
        visibility: visible;
        transition: all 400ms ease;
        z-index: 2;
    }

    &ItemTitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        padding-left: 4px;
        transition: color 400ms ease;
        cursor: pointer;
    }

    &ItemSelected {
        color: $primary_text;
    }

    position: absolute;
    top: 40px;
    left: -12px;
    width: 100%;
    z-index: -1;
}