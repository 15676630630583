.countTickets {
  display: flex;
  gap: 4px;
  align-items: center;
}

.count  {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  color: #EBE9F099;
}